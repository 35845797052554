import React from 'react'
import { AiFillStar } from 'react-icons/ai';

const ReviewCard = ({image, name, tag, review, date, stars}) => {
  return(
    <div className="pt-sans mx-4 my-2 review-card-width" style={{ backgroundColor: 'white', borderRadius: '.5rem', marginTop: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className='mx-2'>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <img className='mx-2' style={{borderRadius: '5rem'}} alt="" src={image} width={50} height={50} />
          <div style={{textAlign: 'left'}}>
            <span>{name}</span>
            <p>{tag}</p>
          </div>
        </div>
        <div className="d-flex">
          {Array.from({ length: stars }, (_, index) => (
            <div key={index}>
              <AiFillStar size={25} color="#e8b427" />
            </div>
          ))}
        </div>
        <div style={{textAlign: 'left'}}>
          <p>{review}</p>
        </div>
      </div>
      <div className='mx-2' style={{textAlign: 'left'}}>
        <p>{date}</p>
      </div>
    </div>
  )
};

export default ReviewCard;
