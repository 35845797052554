import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {

  return (
    <div style={{backgroundColor: '#FBF3EA'}}>
    <div className="privacy-policy-container">
      <h1 className='my-h1'>AdAmigo.ai Privacy Policy</h1>
      <p className='my-p'>This Privacy Declaration outlines the manner in which <span>AdAmigo.ai</span> and our partner entities (referred to collectively as "AdAmigo.ai", "we", "us", or "our") gather, retain, utilize, uncover, and manage personally identifiable data ("Personal Information") during the course of our operations. This encompasses our website found at www.adamigo.ai (the "Website"), as well as our Ads Platform accessible through the Website for the purposes of administering, overseeing, and executing marketing campaigns. These activities, along with the totality of AdAmigo.ai's services, technology, data, programs, and resources, together form the <span>"Services."</span></p>
      <p className='my-p'>We deeply value your privacy. Our dedication lies in ensuring that our approaches to handling your Personal Information maintain both equity and transparency. Kindly peruse this Privacy Declaration attentively to ensure a comprehensive understanding and agreement. Upon accessing the Website or other Services, you demonstrate your comprehension and concurrence with all the terms and conditions outlined within this document. If you disagree with adhering to this Privacy Declaration or would rather not disclose or permit the processing of your Personal Information by us or any of our associates or service providers, please refrain from accessing or utilizing the Website and other Services.</p>
      <p className='my-p'>Any terminology capitalized herein yet not explicitly defined shall acquire the definitions ascribed to them in our Terms of Service Agreement available at https://adamigo.ai/terms-of-service or any linked successor URL ("Agreement"). We encourage you to review the Agreement, as it concurrently regulates your access and utilization of the Services.</p>
      <p className='my-p'>Please note that we do not control any personal data processing conducted by online advertising platforms, third-party services possibly linked to our services, or by our customers. We have added a few helpful links below, but you should always contact those third parties directly for accurate information on their processing.</p>
      <ul><li><a href='https://www.facebook.com/policy.php?ref=pf'>Facebook & Instagram Data Policy</a></li></ul>
      <ol>
        <li>
          <h2 className='my-h2'>Automatic Gathering of Personal Details</h2>
          <ol>
            <li><span>General.</span> At the core of our priorities lies the safeguarding of customer data. As a testament to this commitment, we adhere to a strict "no logs" principle, signifying the absence of stored records or logs regarding your online activities. Our processing of Personal Information is exclusively confined to what is essential for the seamless functioning of our Services. Any automated collection of Personal Information serves the purpose of identifying potential instances of misuse or establishing usage and traffic statistics related to our Services. Please be assured that this statistical data is handled in a manner that prevents the identification of individual users.</li>
            <li><span>Cookies.</span> We may employ "cookies" to effortlessly assemble statistical data via the Website and Services, fostering a personalized online experience for you. A "cookie" is a modest text file embedded in your device's browser, exclusively assigned to you to store your preferences. Although most web browsers facilitate cookie blocking and deletion, please be mindful that such actions could impede the optimal operation of the Website and Services. For an in-depth understanding of the types of cookies we may employ and how they are employed, consult our Cookie Policy at https://adamigo.ai/cookie-policy.</li>
            <li><span>Do Not Track.</span> Certain web browsers provide users with the ability to set a "Do Not Track" (DNT) preference, aimed at preserving privacy. It is important to note that we do not engage with or honor DNT signals or analogous mechanisms transmitted by web browsers. Nevertheless, as elaborated further in this Privacy Policy, we actively curtail the utilization and compilation of your Personal Information.</li>
          </ol>
        </li>
        <li>
          <h2 className='my-h2'>Optional Personal Details</h2>
          <p className='my-p'>You may generally access and use the Website and Services without telling us who you are or revealing Personal Information by which someone could identify you.  However, to use specific features, you may be asked to provide certain Personal Information such as your name and e-mail address.  You may choose not to provide this information, but please note that without this information, we may be unable to provide the full range of our Services and the best user experience.  When you do knowingly provide Personal Information to us, such as when you create an AdAmigo.ai Services account or complete online forms on our Website, we receive and store such information.  We may also collect other Personal Information about you from third-party sources, such as public databases and our joint marketing partners.  If you are uncertain about which Personal Information is optional or mandatory to use the Website and Services, please contact us at support@adamigo.ai</p>
        </li>
        <li>
          <h2 className='my-h2'>Use and Processing of Personal Details</h2>
          <p className='my-p'>In order to make the Website and Services fully available to you, we may need to collect and use certain Personal Information.  If you do not provide the information, we may not be able to provide you with all requested products or services.  Any of the information we collect from you may be used to help us run and operate the Website and Services, including to measure interest and engagement, to improve, troubleshoot, or develop the Services, to ensure quality control and safety, to carry out audits, to communicate with you about your account, your activities on our Services and policy changes, to prevent or prosecute prohibited or illegal activities, to enforce our agreements and to comply with our legal obligations.</p>
          <p className='my-p'>After a user has connected or otherwise authorized our platform to access their ad account or assets on certain online advertising platform(s), we process (where granted with access and to the extent applicable to the services in question):</p>
          <ul>
            <li>The user identification information, such as user ID, user name, user email, and profile picture URL</li>
            <li>Access tokens (more information is available about access rights in the applicable online platform’s community sites, for example regarding Facebook access tokens here)</li>
            <li>Advertiser identification information, such as Ad account ID and Advertiser ID</li>
            <li>Events and log information on the interaction between the ad account and AdAmigo.ai’s application(s)</li>
          </ul>
          <p className='my-p'>Such personal data is used to identify each user and verify their access rights, permit users to access and use the service (i.e. to identify and connect the assets and campaigns and manage these assets and campaigns on the online advertising platform via AdAmigo.ai’s platform, to synchronize campaign entities and metrics between our services and online advertising platform), and to provide support service. We will process such ad account and campaign-related personal data until our access to such data is revoked (including invalidated by using an online advertising platform’s tools).</p>
          <p className='my-p'>How we process Personal Information depends on (a) how you interact with the Website and Services, (b) where you are located in the world, and (c) whether or not one of the following applies:</p>
          <ol>
            <li>You have given your consent to process the information for one or more specific purposes (but this does not apply when subject to the California Consumer Privacy Act (<span>“CCPA”</span>) or European General Data Protection Regulation (<span>“GDPR”</span>));</li>
            <li>Providing the information is necessary for us to perform or fulfill our agreement with you;</li>
            <li>The processing is necessary for us to comply with a legal obligation;</li>
            <li>The processing is related to a task that we carry out in the public interest or in the exercise of official authority that has been vested in us; or</li>
            <li>The processing is necessary for the purposes of the legitimate interests that we or a third party pursue.</li>
          </ol>
          <p className='my-p'>Note that under some laws, we may be allowed to process information until you object (by opting out), without having to rely on consent or any other of the following legal bases below. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular, whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
          <p className='my-p'>We may use Personal Information and other data about you to create de-identified and/or aggregated information, such as de-identified demographic information, de-identified location information, information about the device from which you access our Services, or other analyses we create. De-identified and/or aggregated information is not Personal Information, and we may use and disclose such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes.</p>
        </li>
        <li>
          <h2 className='my-h2'>Managing Information</h2>
          <p className='my-p'>You may delete certain Personal Information that we have about you. The Personal Information that you may delete may change as the Website and Services change.  When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records, solely for the period of time necessary to comply with our obligations to our affiliates and partners, and for the purposes described herein.  If you would like to delete your Personal Information or permanently delete your AdAmigo.ai account, you may do so on the settings page of your account on the Website.</p>
        </li>
        <li>
          <h2 className='my-h2'>Disclosure of Information</h2>
          <p className='my-p'>To maintain the highest level of privacy and protect your Personal Information to the fullest extent, we do not share your Personal Information with anyone or for any reason.</p>
        </li>
        <li>
          <h2 className='my-h2'>Retention of Information</h2>
          <p className='my-p'>We (and our authorized service providers) will store and use the Personal Information that we receive as described in this Privacy Policy, for as long as you use the Website or Services or as reasonably necessary to fulfill the purpose(s) for which the information was collected, to provide our Services, to resolve disputes (in other words, as required by laws applicable to records and bookkeeping, as evidence in connection with claims regarding our relationship in the event that a legal issue arises), to establish legal defenses, to pursue legitimate business purposes, to enforce our agreements and to comply with applicable laws.  Except as required by applicable law or our agreements with you, we will not be obligated to retain Personal Information for any particular period, and we may securely delete it or restrict access to it for any reason and at any time, with or without notice.  If you have any questions about our data retention policy, or if you wish to request that we stop processing or delete your Personal Data (see GDPR section below), please contact us by e-mail at support@adamigo.ai.</p>
        </li>
        <li>
          <h2 className='my-h2'>Transfer of Information</h2>
          <p className='my-p'>Depending on your location, data transfers may involve transferring and storing your information in a country other than your own, including but not limited to, the United States.  As explained in further detail herein, we endeavor to safeguard your information consistent with the requirements of applicable laws.  You are entitled to learn about the legal basis of information transfers to a country outside the European Economic Area (“<span>EEA</span>”) or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by us to safeguard your information. If you have questions about any such transfers, please contact us by e-mail at support@adamigo.ai.</p>
        </li>
        <li>
          <h2 className='my-h2'>Data Protection Rights Under GDPR</h2>
          <p className='my-p'>For the purpose of this Privacy Policy, AdAmigo.ai is the "controller" in respect of certain Personal Data (as defined under the GDPR) such as account registration details, that we collect directly from you and other users of the Services, and that we use for the purposes of our business.  You are the "controller" of your other Personal Data; and we act as the data "processor" and process such Personal Data on your behalf of you, per your instructions.</p>
          <p className='my-p'>Under GDPR, the main legal bases that we rely on to process Personal Data collected by the Services include the following:</p>
          <ol>
            <li><span>Necessary for entering into, or performing, an agreement</span> - to perform obligations that we undertake in providing Services to you or to take steps at your request to enter into an agreement with us, it will be necessary for us to process your Personal Data;</li>
            <li><span>Necessary for compliance with a legal obligation</span> - we are subject to certain legal requirements which may require processing of your Personal Data. We may also be obligated to disclose your Personal Data to a regulatory body or law enforcement agency;</li>
            <li><span>Necessary for the purposes of legitimate interests</span> - either we or a third party, will need to process your Personal Data for the purposes of our (or a third party's) legitimate interests, provided that we have established that those interests are not overridden by your rights and freedoms, including your right to have your Personal Data protected. Our legitimate interests include responding to requests and inquiries from you or a third party, optimizing our Website, platform, and client experience, informing you about our products and Services, and ensuring that our operations are conducted appropriately and efficiently;</li>
            <li><span>Consent</span> - In some circumstances, we may ask for your consent to process your Personal Data in a specific way.</li>
          </ol>
          <p className='my-p'>As noted herein, we may share your Personal Data with trusted third parties where we have retained them to provide services that you or our clients have requested, and to perform maintenance or respond to technical issues involving the Services. You may request a list of sub-processors that we currently engage, by emailing us at support@adamigo.ai.  Where we disclose Personal Data to third parties, we require minimum standards of confidentiality and data protection.</p>
          <p className='my-p'>To the extent that your Personal Data is transferred outside of, or accessed from outside of, the EEA, we will ensure that approved safeguards are in place to comply with GDPR, such as the European Commission's approved standard contractual clauses.  In limited circumstances, transfers of Personal Data may be based on context-specific derogations that permit transfers in the absence of safeguards, such as where a transfer is necessary for the establishment, exercise or defense of legal claims.</p>
          <p className='my-p'>As noted above, we will retain your Personal Data for the time necessary to provide the Services or to achieve other purposes set forth in this Privacy Policy.  To request that we stop processing or delete your Personal Data, please contact us by e-mail at support@adamigo.ai.</p>
          <p className='my-p'>You have the following rights in relation to Personal Data that we hold about you, which rights you may exercise by emailing us at support@adamigo.ai.  We will require evidence of your identity before being able to act upon your request.</p>
          <ol>
            <li>You have the right to request confirmation of whether we are processing your Personal Data.</li>
            <li>You have the right to ask for a copy of your Personal Data. With good reason, and if the GDPR permits, we can refuse your request in whole or in part. If we do refuse, we will provide our reasons for the refusal.</li>
            <li>In certain situations, with respect to Personal Data you have previously provided to us, and that we process by automated means, you have the right to receive an electronic copy in a structured, commonly used and machine-readable format. You may request that we transmit this information to you or to another company (the "right of data portability").</li>
            <li>In certain situations, you have the right to object to or restrict our uses of your Personal Data (for example, if we are processing your Personal Data on the basis of our legitimate interests and there are no compelling legitimate grounds for our processing which override your rights and interests, or if you object to use of your Personal Data for purposes of direct marketing). You may also be entitled to restrict our use of your Personal Data (for example, where you have challenged the accuracy of the Personal Data and while we are verifying its accuracy). To request that we stop processing or delete your Personal Data, please contact us by e-mail at support@adamigo.ai.</li>
            <li>You have the right to seek correction or amendment of Personal Data that is inaccurate, untrue, outdated, or incomplete.</li>
            <li>In certain situations, you have the right to request the erasure of your Personal Data (for example, if the Personal Data is no longer necessary for the purposes for which it was collected or processed, or if our processing is based on your consent and there is no other legal basis for us to process the Personal Data). If you are located in the EEA or the United Kingdom, you have the right to lodge a complaint with a supervisory authority if you believe that our processing of your Personal Data violates applicable law.</li>
          </ol>
          <p className='my-p'>If you are located in the EEA or the United Kingdom, you have the right to lodge a complaint with a supervisory authority if you believe that our processing of your Personal Data violates applicable law.</p>
        </li>
        <li>
          <h2 className='my-h2'>California Privacy Rights</h2>
          <p className='my-p'>This section only applies to our processing of Personal Information that is subject to the California Consumer Privacy Act of 2018 (“<span>CCPA</span>”) or the California Privacy Rights Act of 2020 (“<span>CPRA</span>”). California residents have the right to know what categories of Personal information we have collected about them, whether we disclosed that Personal Information for a business purpose (such as to an affiliate, service provider or business partner) in the past 12 months, and how long we retain the Personal Information. California residents can find this information below:</p>
          <table className="privacy-policy-table">
            <thead>
              <tr>
                <th>Category of Personal Information</th>
                <th>Collected by Us?</th>
                <th>Disclosed by Us for a Business Purpose?</th>
                <th>Categories of Third Parties to Which We Disclose Personal Information for a Business Purpose</th>
                <th>How long we retain the Personal Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Identifiers (such as name, alias, address, unique personal identifier, IP address, email, account name, etc.)</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Our affiliates, Service Providers (such as CRM SaaS offerings), Our business partners (such as resellers)</td>
                <td>As long as necessary for the purposes set out in this Privacy Policy</td>
              </tr>
              <tr>
                <td>Personal Information categories listed in Cal. Civ. Code §1798.80(e) (information that identifies an individual, unless the information is public knowledge)</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Characteristics of protected classifications under California or federal law (such as age, race, religion, national origin, gender identity, sexual orientation, genetic information, marital status, medical conditions, military status, etc.) Commercial information (such as purchase or shopping records)</td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td></td>
              </tr>
              <tr>
                <td>Biometric Information</td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td></td>
              </tr>
              <tr>
                <td>Internet activity or electronic network activity information such as browsing history, search history, interaction history with websites, apps or ads</td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td></td>
              </tr>
              <tr>
                <td>Geolocation data</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Audio, electronic, visual, thermal, olfactory, or similar information</td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td></td>
              </tr>
              <tr>
                <td>Professional or employment-related information</td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td></td>
              </tr>
              <tr>
                <td>Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)) (such as name or address of a student or their family, other student identification information)</td>
                <td>No</td>
                <td>N/A</td>
                <td>N/A</td>
                <td></td>
              </tr>
              <tr>
                <td>Inferences drawn from other Personal Information to create a profile reflecting your preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities or aptitudes</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>If CPRA applies, Sensitive Personal Information, which includes: any communications you have with a third party, biometric data processed to identify an individual, data about sexual orientation or sex life, financial account details in a combination (e.g., card number and password) that gives access to an account, genetic data, government-issued numbers (such as a social security number, passport number, driver’s license number), health data, philosophical or religious beliefs, precise geolocation data, racial or ethnic origin, or union membership data</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p className='my-p'>For purposes of California law, we do not “sell” Personal Information except as may be disclosed herein (“sell” is broadly defined under California law), nor do we have actual knowledge of any “sale” of Personal Information of minors under 16 years of age.</p>
          <p className='my-p'>For purposes of California law, we do not “share” Personal Information (in other words, we do not share Personal Information with a third party for cross-context behavior advertising or interest-based advertising, whether or not for payment).</p>
          <p className='my-p'>In addition to the GDPR rights above, if you are a California resident, you (and in some cases, you authorized agent) may contact us to request the deletion of your Personal Information. You may also contact us to request correction of your personal data.</p>
          <p className='my-p'>To the extent that we may use any data profiling technology automated decision-making technology that we may use to profile your habits, interests, or economic activity, you have the right to learn about that technology, and opt out of us using your information for profiling you across the following categories: behavior, economic situation, health, interests, location or movements, performance at work, personal preferences, or reliability.</p>
          <p className='my-p'>To the extent that we may process any Sensitive Personal Information under the CPRA, you may contact us to opt out of us using this information for anything other than supplying goods or services that you request.</p>
          <p className='my-p'>California residents have the right not to receive discriminatory treatment by AdAmigo.ai for the exercise of their rights conferred by the CCPA or CPRA.</p>

        </li>   
        <li>
          <h2 className='my-h2'>How to Exercise Your Rights</h2>
          <p className='my-p'>Any requests to exercise your rights can be directed through the Website as indicated, or you may contact us by e-mail at support@adamigo.ai or as otherwise set forth below. Your request must include sufficient details to allow us to properly understand the request and respond to it.  And before we respond, we may ask you to verify your identity with sufficient information.  We cannot respond or provide Personal Information unless we first verify your identity or authority to make such a request, and confirm that the Personal Information relates to you.</p>
        </li>    
        <li>
          <h2 className='my-h2'>Privacy of Children</h2>
          <p className='my-p'>The Website and Services are not directed to individuals under the age of 18, and we do not knowingly collect Personal Information from children. If you learn that your child has provided Personal Information to us without your consent, you may contact us by e-mail at support@adamigo.ai or as otherwise set forth below. If we learn that we have collected a child's Personal Information in violation of applicable law, we will promptly take steps to delete such information and terminate the child's account.</p>
        </li>
        <li>
          <h2 className='my-h2'>Links to Other Resources</h2>
          <p className='my-p'>The Website and Services may contain links to other websites/applications, and other websites/applications may reference or link to us. We do not control these third-party services. We encourage you to carefully read the privacy policies and other applicable terms and policies of each third-party website and application with which they interact. Subject to the terms herein, we do not endorse, screen or approve, and we are not responsible for, the privacy practices or content of such other websites or applications. You provide Personal Information to third-party websites or applications at your own risk.</p>
        </li>
        <li>
          <h2 className='my-h2'>Information Security</h2>
          <p className='my-p'>We secure the Personal Information that you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure.  We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure.  However, no data transmission over the Internet or wireless network can be fully guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge: (a) the Internet has some security and privacy limitations that are beyond our control; (b) we cannot guarantee the security, integrity, and privacy of information and data exchanged between you and the Website and Services; and (c) despite best efforts, a third party may view or tamper with any such information and data while in transit.</p>
        </li>
        <li>
          <h2 className='my-h2'>Data Breach</h2>
          <p className='my-p'>If we become aware that the security of the Website and Services has been compromised or that users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities.  If there is a data breach, we will make reasonable efforts to notify affected individuals, if we believe that there is a reasonable risk of harm to the user as a result of the breach or if the notice is otherwise required by law. When we do, we will post a notice on the Website or Services, or we will send you an email.</p>
        </li>
        <li>
          <h2 className='my-h2'>Changes and Amendments</h2>
          <p className='my-p'>We may update and amend this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Website or Services after the new Privacy Policy takes effect.  However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.</p>
        </li>
        <li>
          <h2 className='my-h2'>Contach Us</h2>
          <p className='my-p'>If you would like to contact us to understand more about this Privacy Policy or concerning any matter relating to individual rights and your Personal Information, please contact us at:</p>
            AdAmigo.ai.<br />
            Technikerstraße 82<br />
            6020 Innsbruck, <br />
            Tirol, Austria<br />
            Email: support@adamigo.ai<br />
            Web:  <a href='https://adamigo.ai'>www.adamigo.ai</a>
        </li>
      </ol>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
