import React from 'react'

const Header = () => {
  return(
    <div className="mb-3 pt-3 d-flex justify-content-between" >
      <img className='header-image' alt="" src="/d.png" />
      <p style={{color: 'grey'}} ><span>Free</span> Meta Ads Grader</p>
    </div>
  )
};

export default Header;
