import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_CHATBOT,
  ROUTE_SETTINGS,
  ROUTE_NOT_FOUND,
  ROUTE_PRIVACY_POLICY,
  ROUTE_FORGET_PASSWORD,
  ROUTE_META_ADS_GRADER,
  ROUTE_META_ADS_GRADER_KPIS,
  ROUTE_META_ADS_GRADER_RESULTS,
  ROUTE_META_ADS_GRADER_BUSINESS,
  ROUTE_META_ADS_GRADER_LOADING_RESULTS,
} from './constants';

import Login from './Login';
import Signup from './Signup';
import Chatbot from './ChatBot';
import KpisPage from './KpisPage';
import SurveyPage from "./SurveyPage";
import LandingPage from "./LandingPage";
import ResultsPage from './ResultsPage';
import LoadingPage from './LoadingPage';
import NotFoundPage from './NotFoundPage'; 
import SettingsPage from './SettingsPage';
import PrivacyPolicy from './PrivacyPolicy';
import ForgetPassword from './ForgetPassword';
import { AdsGraderProvider } from './AdsGraderContext';

function App() {

  return (
    <div className="App">
      <AdsGraderProvider>
      <Router>
        <Routes>
          <Route path={ROUTE_LOGIN} element={<Login/>}/>
          <Route path={ROUTE_SIGNUP} element={<Signup/>}/>
          <Route path={ROUTE_CHATBOT} element={<Chatbot/>}/>
          <Route path={ROUTE_SETTINGS} element={<SettingsPage/>}/>
          <Route path={ROUTE_META_ADS_GRADER} element={<LandingPage/>}/>
          <Route path={ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
          <Route path={ROUTE_META_ADS_GRADER_KPIS} element={<KpisPage/>}/>
          <Route path={ROUTE_FORGET_PASSWORD} element={<ForgetPassword/>}/>
          <Route path={ROUTE_META_ADS_GRADER_BUSINESS} element={<SurveyPage/>}/>
          <Route path={ROUTE_META_ADS_GRADER_RESULTS} element={<ResultsPage/>}/>
          <Route path={ROUTE_HOME} element={<Navigate to={ROUTE_META_ADS_GRADER}/>}/>
          <Route path={ROUTE_META_ADS_GRADER_LOADING_RESULTS} element={<LoadingPage/>}/>
          <Route path={ROUTE_NOT_FOUND} element={<NotFoundPage/>}/>
        </Routes>
      </Router>
      </AdsGraderProvider>
    </div>
  );
}

export default App;
