import React from 'react'

const ScoreBar = ({name, score, side_bar = true}) => {

  var bg_color = ''
  if (score > 80) {
    bg_color = '#05FF00'
  } else if (score <= 40) {
    bg_color = '#ED3E63'
  } else {
    bg_color = '#FFE500'
  }

  return(<>
    {side_bar ? 
    <div className='my-3'>
      <div className='d-flex justify-content-between'>
        <p style={{margin: '0px', fontSize: '18px'}}><span style={{margin: '0px', fontSize: '18px', fontWeight: 'bolder'}}>{name}</span> Score</p>
        <p style={{margin: '0px', fontSize: '16px'}}><span style={{margin: '0px', fontSize: '20px', fontWeight: 'bolder'}}>{score}</span> /100</p>
      </div>
      <div class="progress-container" style={{maxWidth: '250px'}}>
        <div style={{content: '""', background: bg_color, position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)', height: '7px', width: `${score}%`, zIndex: 1, borderRadius: '1rem'}}></div>
      </div>
    </div>: 
    <div className='my-1'>
      <div className='d-flex justify-content-center'>
        <p style={{margin: '0px', fontSize: '14px'}}><span style={{color: bg_color, margin: '0px', fontSize: '20px', fontWeight: 'bolder'}}>{score}</span> /100</p>
        </div>
      <div class="progress-container" style={{maxWidth: '250px'}}>
        <div style={{content: '""', background: bg_color, position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)', height: '7px', width: `${score}%`, zIndex: 1, borderRadius: '1rem'}}></div>
      </div>
      <p style={{margin: '0px', fontSize: '20px'}}><span style={{margin: '0px', fontSize: '20px', fontWeight: 'bolder'}}>{name}</span> Score</p>
    </div>}
  </>)
};

export default ScoreBar;
