
import './SettingsPage.scss';
import Box from "@mui/material/Box";
import { IoMdAdd } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { ModalBody, ModalHeader, Row, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BsFacebook } from 'react-icons/bs';
import { FaCheck } from "react-icons/fa6";
import { ROUTE_CHATBOT } from './constants';
import FacebookLoginRender from 'react-facebook-login/dist/facebook-login-render-props';


const ConnectAds = ({step, haveToken = true}) => {
  const navigate = useNavigate();
  
  const [haveToken_, setHaveToken] = useState(haveToken);
  const [isToggled, setIsToggled] = useState(true);
  const [adaccounts, setAdaccounts] = useState([]);
  const [selectedAdaccountIds, setSelectedAdaccountIds] = useState([]);
  
  const [tab, setTab] = useState(step);
  const access_token = localStorage.getItem('token');


  const variantValue = (value) => {
    return selectedAdaccountIds.includes(value) ? "#ed3e63" : "#ddd";
  };

  const variantValue2 = (value) => {
    return selectedAdaccountIds.includes(value) ? "white" : "black";
  };

  const addSign = (value) => {
    return value === "#ed3e63" ? (
      <IoMdAdd size={20} className="float-start text-white" />
    ) : (
      ""
    );
  };

  const handleCheckboxChange = (id) => {
    console.log(selectedAdaccountIds)
    if (selectedAdaccountIds.includes(id)) {
      setSelectedAdaccountIds(prevSelected => prevSelected.filter(item => item !== id));
    } else {
      setSelectedAdaccountIds(prevSelected => [...prevSelected, id]);
    }
  };

  const handleSelectAll = () => {
    setIsToggled(!isToggled);
    debugger
    if (selectedAdaccountIds.length === adaccounts.length) {
      setSelectedAdaccountIds([])
    } else {
      const allIds = adaccounts.map(adaccount => adaccount.id);
      setSelectedAdaccountIds(allIds);
    }
  }

  useEffect (() => {
    fetch('https://adamigo-devps.adamigo.ai/chatbot/ad_accounts/', { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({access_token: access_token})})
      .then((response) => response.json())
      .then((data) => {
        setAdaccounts(data);
        setSelectedAdaccountIds(data.map(item => item.id))
       })
      .catch((error) => {
        console.error("Error:", error);
      }
    );
  }, [access_token])

  const responseFacebook = (response) => {
    if (response.accessToken) {
      const graphApiVersion = 'v18.0';
      const appId = '105616075874993'; 
      const appSecret = 'f7254f1633ee8645491c2a676ec48302'; 
      const accessToken = response.accessToken;

      fetch(`https://graph.facebook.com/${graphApiVersion}/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          localStorage.setItem('token', data.access_token);
          setHaveToken(true);
          setTab(2);
        })
        .catch(error => {
          console.error('Error:', error);
      });
    } else {
      console.log(response)
    }
  }

  return (
    <div className='apply-background' style={{padding: '0 10px 1px 10px', height: '98%', borderRadius: '1em'}}>
      {tab === 2 && <div style={{height: '100%'}}>
        {/* <img className='my-4' alt='logo' width={180} height={44}  src="/d.png" /> */}
        <h2 className="pt-serif">Connect Your Ad Accounts</h2>
        <p className="m-0">You can ad and remove ad accounts anytime inside the AdAmigo AI app.</p>
        <div className='d-flex align-items-center py-2 mt-2' onClick={() => setTab(1)} style={{backgroundColor: 'white', cursor: 'pointer'}}>
          <BsFacebook color='blue' size={35} className='mx-2' />
          <div className='mx-2' style={{color: 'black'}}>
            <p className='m-0'>Facebook Ads</p>
            <div className='d-flex'>
              <FaCheck color='green' size={20} />
              <p className='m-0' style={{color: 'green'}}>Connected</p>
            </div>
          </div>
        </div>
        <div className='my-3' style={{backgroundColor: 'white', height: '78%', borderRadius: '1em'}}>
          <ModalHeader className="mx-3">
            <p className="m-0">Select ad account to connect to AdAmigo AI:</p>
          </ModalHeader>
          <ModalBody className="mx-3 ads-model-2">
            <div className="d-flex align-items-center justify-content-center">
              Select all:&nbsp;&nbsp;
              <div className="toggle-switch" onClick={handleSelectAll}>
                <div className={`slider ${isToggled ? 'toggled' : ''}`}></div>
              </div>
            </div>
            <Row className="d-flex justify-content-center m-0">
              {Object.values(adaccounts || {}).map((value) => {
                return (
                  <Button
                    title={value.name}
                    key={value.id}
                    className="mt-2 px-3 text-break text-black border-0 d-block"
                    style={{backgroundColor: `${variantValue(value.id)}`, display: 'flex', alignItems: 'center'}}
                    onClick={(e) => handleCheckboxChange(e.currentTarget.id)}
                    id={value.id}
                  >
                    {addSign(variantValue(value.id))}
                    <Box
                      style={{color: `${variantValue2(value.id)}`}}
                        sx={{ borderLeft: "1px solid gray", marginLeft: "8%",}}
                        onClick={(e) => handleCheckboxChange(e.currentTarget.id)}
                        id={value.id}
                      >
                      {value.name}
                    </Box>
                  </Button>
                );
              })}
            </Row>
          </ModalBody>
          <Button className="my-3 border-0" onClick={() => {
            console.log(adaccounts.filter(item => selectedAdaccountIds.includes(item.id)))
            navigate(ROUTE_CHATBOT)
          }}>Connect</Button>
        </div>
      </div>}
      {tab === 1 && <div style={{maxWidth: '500px'}}>
        <div style={{width: '100%', margin: '0 auto'}}>
          <img className='my-4' alt='logo' width={180} height={44}  src="/d.png" />
          <h2 className="mb-4 pt-serif" style={{width: '80%', margin: '0 auto'}}>Connect Your Advertising Channel</h2>
          <p className="m-0">AdAmigo AI will not take any action inside your ad accounts without your explicit request. Disconnect AdAmigo AI from your advertising channels anytime.</p>
        </div>
        {haveToken_ && <div className='d-flex align-items-center py-2 mt-4' onClick={() => setTab(2)} style={{backgroundColor: 'white', cursor: 'pointer'}}>
          <BsFacebook color='blue' size={35} className='mx-2' />
          <div className='mx-2' style={{textAlign: 'left', color: 'black'}}>
            <p className='m-0'>Facebook Ads</p>
            <p className='m-0' style={{color: '#ed3e63'}}>Click to Connect</p>
          </div>
        </div>}
        {!haveToken_ && <FacebookLoginRender
          appId="105616075874993"
          fields="name,email"
          scope="public_profile,email,pages_show_list,pages_read_engagement,read_insights,pages_read_user_content,pages_manage_engagement,ads_read,ads_management,business_management,pages_manage_metadata,pages_messaging,pages_manage_posts,pages_manage_ads,leads_retrieval"
          autoLoad={false}
          callback={responseFacebook}
          render={renderProps => (
            <div className='d-flex align-items-center py-2 mt-4' onClick={renderProps.onClick} style={{backgroundColor: 'white', cursor: 'pointer'}}>
              <BsFacebook color='blue' size={35} className='mx-2' />
              <div className='mx-2' style={{textAlign: 'left'}}>
                <p className='m-0'>Facebook Ads</p>
                <p className='m-0' style={{color: '#ed3e63'}}>Click to Connect</p>
              </div>
            </div>
          )}
        />}
        <div className='my-5'>
          <p className='my-5'><span>Note:</span> You can select which ad account to connect in the next step.</p>
        </div>
        <div className='my-5'>
          <p className='m-0 my-4' style={{color: 'gray'}}>For detailed information on how AdAmigo AI requires and utilizes access to your Facebook token for ad account data management, please click <a className='' style={{color: 'ed3e63', cursor: 'pointer'}}>here</a></p>
        </div>
      </div>}
    </div>
  );
};

export default ConnectAds;
