import React, { useContext, useEffect, useState } from "react";

import Header from "./shared/Header";
import { AdsGraderContext } from "./AdsGraderContext";
import { useNavigate } from 'react-router-dom';

const LoadingPage = () => {

  const { 
    userEmail,
    setResults,
    businessType,
    advertisingGoal,
    businessVertical,
    advertisedContinents,
    leads,
    clicks,
    revenue,
    purchases,
    addToCarts,
    amount,
    impressions,
    outboundClicks,
    landingPageViews,
  } = useContext(AdsGraderContext);

  const navigate = useNavigate();
  useEffect(() => {
    const formData = new FormData();
    function appendField(key, value) {
      if (value !== '') {
        formData.append(key, value);
      }
    }

    appendField('user_email', userEmail);
    appendField('business_type', businessType);
    appendField('advertising_goal', advertisingGoal);
    appendField('business_vertical', businessVertical);
    appendField('advertised_continents', advertisedContinents);
    appendField('leads', leads);
    appendField('clicks_all', clicks);
    appendField('revenue', revenue);
    appendField('purchases', purchases);
    appendField('adds_to_cart', addToCarts);
    appendField('amount_spent', amount);
    appendField('impressions', impressions);
    appendField('outbound_clicks', outboundClicks);
    appendField('landing_page_views', landingPageViews);

    fetch('https://adgrader.adamigo.ai/submit-data/', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      console.log('Response data:', data);
      setResults(data);
      navigate('/meta-ads-grader/results')
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }, []);

  const [score, setScore] = useState(0);

  useEffect(() => {
    const increment = (100 * 10) / 3000;
    const intervalId = setInterval(() => {
      setScore((prevScore) => {
        const newScore = prevScore + increment;
        if (newScore >= 100) {
          clearInterval(intervalId); 
          return 100;
        }
        return newScore;
      });
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  return(
    <div className="mx-5 pt-sans" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
      <Header margin='mb-3 pt-3' />

      <div className="d-flex justify-content-center align-items-center" style={{flexGrow: 1}}>
        <div style={{flexDirection: 'column', textAlign: 'center'}}>
          <p>We are generating Personalized report...</p>
          <h1 className="pt-serif">Calculating Critical KPIs</h1>
          <div class="progress-container justify-content-center" style={{width: '450px', maxWidth: '80vw'}}>
            <div style={{background: '#ED3E63', position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)', height: '7px', width: `${score}%`, zIndex: 1, borderRadius: '1rem'}}></div>
            <div className="d-flex justify-content-end align-items-center loader-container" style={{position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)', height: '7px', width: `${score+4}%`, zIndex: 1, borderRadius: '1rem'}}>
              <img src='/k.png' alt="Loader" className="loader" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 pt-5" style={{ display: 'flex', alignItems: 'flex-end', fontSize: '12px' }}>
        <a>Privacy Policy</a>
        <a className="terms-margin">Terms & conditions</a>
        <a>Contact Us</a>
      </div>
    </div>
  )
};

export default LoadingPage;
