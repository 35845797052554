import React from 'react'

const PrivacyTag = ({fontSize, classN = 'justify-content-center', mwidth = '450px'}) => {

return(
  <div className={`my-2 d-flex ${classN}`}>
    <img width={40} height={40} alt="" src="/l.png" />
    <div className="mx-2" style={{maxWidth: mwidth}}>
      <p style={{margin: '0px', fontSize: fontSize, textAlign: 'left'}}>
      Privacy Guaranteed: Your data is never shared. You don't have to
      disclose your Business Name or connect your Ad Account, for increased
      anonymity.</p>
    </div>
  </div>
)}

export default PrivacyTag;
