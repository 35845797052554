import { Input } from "@mui/material";
import { AiFillCheckCircle } from 'react-icons/ai';
import { GoDotFill } from 'react-icons/go';
import Header from "./shared/Header";
import PrivacyTag from "./shared/PrivacyTag";
import { IoMdArrowRoundForward } from "react-icons/io";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from "react";
import { AdsGraderContext } from "./AdsGraderContext";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "./shared/metaPixel";

const KpisPage = () => {

  const {
    amount,
    setAmount,
    revenue,
    setRevenue,
    impressions,
    setImpressions,
    clicks,
    setClicks,
    outboundClicks,
    setOutboundClicks,
    landingPageViews,
    setLandingPageViews,
    addToCarts,
    setAddToCarts,
    purchases,
    setPurchases,
    leads,
    setLeads,
  } = useContext(AdsGraderContext); 

  const navigate = useNavigate();
  const handleSubmitMetrics = () => {

    trackEvent('KPIsSubmitted');
    const requiredFieldsErrors = [
      { value: amount, name: 'Amount spent is required' },
      { value: revenue, name: 'Revenue is required' },
      { value: impressions, name: 'Impressions are required' },
      { value: clicks, name: 'Clicks(all) are required' },
      { value: landingPageViews, name: 'Landing Page Views are required' },
    ].filter(f => !f.value).map(f => `${f.name}`);

    const metrics = [
      { value: impressions, name: 'Impressions' },
      { value: clicks, name: 'Clicks' },
      { value: outboundClicks, name: 'Outbound Clicks' },
      { value: landingPageViews, name: 'Landing Page Views' },
      { value: addToCarts, name: 'Add to Carts' },
      { value: purchases, name: 'Purchases' },
    ];

    const filledMetrics = metrics
      .map(metric => ({ ...metric, value: metric.value ? Number(metric.value) : null }))
      .filter(metric => metric.value !== null);

    const hierarchyErrors = filledMetrics
      .slice(1)
      .map((metric, i) => {
        const previousMetric = filledMetrics[i];
        if (metric.value > previousMetric.value) {
          return `${metric.name} must be less than or equal to ${previousMetric.name}`;
        }
        return null;
      })
      .filter(error => error);

    if (leads && Number(leads) >= Number(clicks)) {
      hierarchyErrors.push('Leads must be less than Clicks(all)');
    }

    const allErrors = [...requiredFieldsErrors, ...hierarchyErrors];

    if (allErrors.length > 0) {
      allErrors.forEach(error => toast.error(error));
      return;
    }

    navigate('/meta-ads-grader/loading-results')
  };

  return(
    <div className="pt-sans" style={{display: 'flex', backgroundColor: '#FBF3EA', justifyContent: 'center'}}>
      <div style={{width: '100%', maxWidth: '1500px'}}>
      <div className="survey-page-margins">
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
        <Header />
        <div class="progress-container" style={{width: '30%', minWidth: '200px'}}>
          <div class= "circle active" onClick={() => navigate("/meta-ads-grader")}><AiFillCheckCircle size={50} color="#ed3e63" /></div>
          <div />
          <div class="circle active" onClick={() => navigate("/meta-ads-grader/business")}><AiFillCheckCircle size={50} color="#ed3e63" /></div>
          <div className="progress-3"></div>
          <div class="circle active"><GoDotFill size={50} color="#ed3e63" /></div>
          <div />
          <div class="circle"></div>
        </div>
        <div className="progress-container-copy my-0" style={{ width: '35%', minWidth: '280px'}}>
          <p style={{paddingLeft: '2rem'}}>Contact</p>
          <p style={{paddingLeft: '0.8rem'}}>Business</p>
          <p style={{paddingLeft: '1.4rem'}}>Metrics</p>
          <p style={{paddingRight: '1.4rem'}}>Get Your Report</p>
        </div>
        <div className="mt-4 page_goal " style={{textAlign: 'left'}}>
          <h1 className="pt-serif h1-font4">Share your performance: (for the last 30 days):</h1>
          <p className="p-font3" >Enter metrics manually for anonymity, instead of connecting your ad account.
          Provide your metrics, and we'll calculate all critical figures and generate the benchmark report.
          We recommend to get an updated report every 30 days.</p>
        </div>
        <div className="form-container mt-4">
          <div className="grid">
            <div className="align-kpis">
              <div>
                <span className="mb-2" ><span className="text-danger">*</span>Amount Spend in USD:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your amount spend" value={amount} onChange={(e) => setAmount(e.target.value)} />
              </div>
              <div className="my-3">
                <span className="mb-2" ><span className="text-danger">*</span>Revenue in USD:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Revenue" value={revenue} onChange={(e) => setRevenue(e.target.value)} />
              </div>
              <div>
                <span className="mb-2" ><span className="text-danger">*</span>Impressions:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Impressions" value={impressions} onChange={(e) => setImpressions(e.target.value)} />
              </div>
            </div>
            <div className="align-kpis">
              <div>
                <span className="mb-2" ><span className="text-danger">*</span>Clicks(All):</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Clicks (all)" value={clicks} onChange={(e) => setClicks(e.target.value)} />
              </div>
              <div className="my-3">
                <span className="mb-2" >Outbound Clicks/Link clicks:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Outb. Clicks" value={outboundClicks} onChange={(e) => setOutboundClicks(e.target.value)} />
              </div>
              <div>
                <span className="mb-2" ><span className="text-danger">*</span>Landing Page Views:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your L.P Views" value={landingPageViews} onChange={(e) => setLandingPageViews(e.target.value)} />
              </div>
            </div>
            <div className="align-kpis">
              <div>
                <span className="mb-2" >Add to Carts:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Adds to Carts" value={addToCarts} onChange={(e) => setAddToCarts(e.target.value)} />
              </div>
              <div className="my-3">
                <span className="mb-2" >Purchases:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Purchases" value={purchases} onChange={(e) => setPurchases(e.target.value)} />
              </div>
              <div>
                <span className="mb-2" >Leads:</span><br/>
                <Input type="number" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Leads" value={leads} onChange={(e) => setLeads(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        <button className="btn my-5" style={{ backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem' }} onClick={handleSubmitMetrics} >Submit Your Metrics Here <IoMdArrowRoundForward size={20} /></button>
        <PrivacyTag fontSize='small' />
        <div className="mt-5 pt-5" style={{ display: 'flex', alignItems: 'flex-end', fontSize: '12px' }}>
          <a >Privacy Policy</a>
          <a className="terms-margin">Terms & conditions</a>
          <a >Contact Us</a>
        </div>
      </div>
      </div>
    </div>
  )
};

export default KpisPage;
