import React, { useContext, useEffect } from 'react'
import Tips from './shared/Tips';                
// import Header from './shared/Header';
import StatCard from './shared/StatCard';
import ScoreBar from './shared/ScoreBar';

import { DashedProgress } from 'react-dashed-progress';
import { IoMdArrowRoundForward } from "react-icons/io";
import { AdsGraderContext } from './AdsGraderContext';
import { trackEvent } from './shared/metaPixel';

const ResultsPage = () => {

  const { results } = useContext(AdsGraderContext);
  trackEvent('ResultsView');

  const handleCTA = () => {
    trackEvent('CTAForCall');
    window.open('https://calendly.com/adamigoai/meta-growth-strategy', '_blank');
  }

  var bg_color = ''
  var status = ''
  if (parseInt(results['total_score']) > 80) {
    bg_color = '#05FF00'
    status = 'Strong'
  } else if (parseInt(results['total_score']) <= 40) {
    bg_color = '#ED3E63'
    status = 'Poor'
  } else {
    bg_color = '#FFE500'
    status = 'Average'
  }

  const kpi_scores = results['Final_Compared_Results'];

  useEffect(() => {
    const textElement = document.querySelector("text");
    if (textElement) {
      textElement.textContent = parseInt(results['total_score']);
    }
  });

  return(

  <div className="d-flex flex-container" style={{backgroundColor: 'whitesmoke', fontFamily: 'sans-serif'}}>
    <div className='d-flex align-items-center pt-5 mb-5 side-bar'>
      <h4 style={{fontWeight: '400', marginBottom: '0px'}}>Your Score is</h4>
      <h3 style={{fontWeight: '600', marginTop: '0px', fontSize: '2rem'}}>{status}</h3>
      <div className="mt-3"><DashedProgress strokeColor={bg_color} value={parseInt(results['total_score']/2)} trailThickness={1} strokeThickness={3} countBars={50} strokeLinecap='butt' /></div>

      <div className='mt-3 mb-2'>
        <ScoreBar name='Performance ' score={parseInt(results['category_scores']['Performance Score'][0])} />
        <ScoreBar name='Cost ' score={parseInt(results['category_scores']['Cost Score'][0])} />
        <ScoreBar name='Creative ' score={parseInt(results['category_scores']['Creative Score'][0])} />
        <ScoreBar name='Funnel ' score={parseInt(results['category_scores']['Funnel Score'][0])} />
      </div>

      <h5 className='mt-3' style={{fontWeight: '500'}}>Want to improve your score?</h5>
      <p className='px-4 mt-2' style={{maxWidth: '300px', fontWeight: '400'}}>Book a 1-on-1 for a live audit. We'll outline steps to boost your score in 30 days, either alone or with our support.</p>
      <button className="btn mb-5" onClick={handleCTA} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem'}}>Get Your Growth Strategy</button>
    </div>

    <div className="mx-2 d-flex justify-content-center align-items-center main-results">
      <div style={{width: '1050px'}} className='header-container'>
        <div className="mb-3 pt-3 d-flex justify-content-between" >
          <img className="mx-3 header-image" alt="" src="/d.png" />
          <p style={{color: 'grey'}} className="mx-3"><span>Free</span> Meta Ads Grader</p>
        </div>
      </div>
      <div className='mt-3' style={{backgroundColor: 'white', maxWidth: '846px', borderRadius: '1.5rem', boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
        <div className='pt-4 d-flex justify-content-center align-items-center'>
          <h2 style={{fontSize: '30px', fontWeight: 'bold'}}>Your score is&nbsp;</h2>
          <h2 style={{color: bg_color, fontSize: '35px', fontWeight: 'bold'}}>{status}</h2>
        </div>
        <p className='mx-5 mt-2' style={{fontSize: 'medium', lineHeight: '1.3rem'}}>Congrats on assessing your Meta ads! We've grouped your Metrics into three performance pillars: optimal ad performance, cost efficiency, and high traffic quality for solid funnel performance. Weakness in any score highlights improvement areas for better overall performance.</p>
        <button className="btn mt-1" onClick={handleCTA} style={{backgroundColor: '#ed3e63', color: 'white' , borderRadius: '2rem'}}>Book Audit Call To Improve Score <IoMdArrowRoundForward size={20} /></button>
        <div className='my-3 d-flex justify-content-center align-items-center'>
          <p className='mx-1' style={{marginBottom: '0px'}}>Available for Calls:</p>
          <img style={{borderRadius: '1rem'}} alt='' width={25} height={25} src='/chris.png' />
          <img style={{borderRadius: '1rem'}} alt='' width={25} height={25} src='/ahmad.png' />
        </div>
      </div>

      <div className='mt-5'>
        <ScoreBar name='Performance ' score={parseInt(results['category_scores']['Performance Score'][0])} side_bar={false} />
        <div className='d-flex justify-content-center flex-container mt-4' style={{alignItems: 'stretch'}}>
          <StatCard 
            name='Return on Ad Spend'
            desciption='The higher the Return on Ad Spend (ROAS) the more profitable your ads. The min. ROAS you should aim for depends on your profit margin.'
            remark={kpi_scores['Return on Ad Spend']['status']}
            userScore={kpi_scores['Return on Ad Spend']['user']}
            compScore={kpi_scores['Return on Ad Spend']['competitor']}
          />

          <StatCard 
            name='Cost per Purchase'
            desciption='This metric shows you how much you have to spend to generate a purchase from your ads compared to your competition.'
            remark={kpi_scores['Cost per Purchase']['status']}
            prefix='$'
            userScore={kpi_scores['Cost per Purchase']['user']}
            compScore={kpi_scores['Cost per Purchase']['competitor']}
          />

          <StatCard 
            name='Cost per Lead'
            desciption='See how much you spend on average to generate a lead compared to your competitors. '
            remark={kpi_scores['Cost per Lead']['status']}
            prefix='$'
            userScore={kpi_scores['Cost per Lead']['user']}
            compScore={kpi_scores['Cost per Lead']['competitor']}
          />
        </div>
      </div>

      <div className='mt-4'>
        <Tips tips={results['Suggestions']['Performance Score']} />
      </div>

      <div className='mt-5'>
        <ScoreBar name='Cost ' score={parseInt(results['category_scores']['Cost Score'][0])} side_bar={false} />
        <div className='d-flex justify-content-center flex-container mt-4' style={{alignItems: 'stretch'}}>
          <StatCard 
            name='CPM'
            desciption='CPM represents your cost per 1000 impressions. The lower the CPM the more people you can reach for the same amount of money spend.'
            remark={kpi_scores['CPM']['status']}
            prefix='$'
            userScore={kpi_scores['CPM']['user']}
            compScore={kpi_scores['CPM']['competitor']} 
          />

          <StatCard 
            name='Cost per Click (all)'
            desciption='Cost Per Click (All) accounts for every click on your ad (e.g., pause video, expand ad copy, click on link), impacting ultimately the cost of traffic and purchases.'
            remark={kpi_scores['Cost per Clicks (all)']['status']}
            prefix='$'
            userScore={kpi_scores['Cost per Clicks (all)']['user']}
            compScore={kpi_scores['Cost per Clicks (all)']['competitor']} 
          />

          <StatCard 
            name='Cost per Outbound Click'
            desciption='Outbound clicks are those clicks leading to your website. If the cost is too high it means buying your traffic will become ultimately too expensive.'
            remark={kpi_scores['Cost per Outbound Click']['status']}
            prefix='$'
            userScore={kpi_scores['Cost per Outbound Click']['user']}
            compScore={kpi_scores['Cost per Outbound Click']['competitor']}
          />
        </div>
      </div>

      <div className='mt-4'>
        <Tips tips={results['Suggestions']['Cost Score']} />
      </div>

      <div className='mt-5'>
        <ScoreBar name='Creative ' score={parseInt(results['category_scores']['Creative Score'][0])} side_bar={false} />
        <div className='d-flex justify-content-center flex-container mt-4' style={{alignItems: 'stretch'}}>
          <StatCard 
            name='CTR (all)'
            desciption='Click Through Rate (All) shows the percentage of users who saw your ad and engaged with it. A low ratio indicates your ad is not garnering attention.'
            remark={kpi_scores['CTR (all)']['status']}
            suffix='%'
            userScore={kpi_scores['CTR (all)']['user']}
            compScore={kpi_scores['CTR (all)']['competitor']}
          />

          <StatCard 
            name='Outbound CTR'
            desciption='Outbound CTR shows us the percentage of users who saw your ad and clicked onto your landing page. If this number is low your call to actions need to be optimized.'
            remark={kpi_scores['Outbound CTR']['status']}
            suffix='%'
            userScore={kpi_scores['Outbound CTR']['user']}
            compScore={kpi_scores['Outbound CTR']['competitor']} 
          />

          <StatCard 
            name='CTR (all) to Outb CTR Dropoff'
            desciption='A lower ratio indicates fewer users with initial interest took action to visit your page. A big drop off suggests your CTAs need improvement.'
            remark={kpi_scores['CTR (all) to Outbound CTR Dropoff']['status']}
            suffix='%'
            userScore={kpi_scores['CTR (all) to Outbound CTR Dropoff']['user']}
            compScore={kpi_scores['CTR (all) to Outbound CTR Dropoff']['competitor']} 
          />
        </div>
      </div>

      <div className='mt-4'>
        <Tips tips={results['Suggestions']['Creative Score']} />
      </div>

      <div className='mt-5'>
        <ScoreBar name='Funnel ' score={parseInt(results['category_scores']['Funnel Score'][0])} side_bar={false} />
        <div className='d-flex justify-content-center flex-container mt-4' style={{alignItems: 'stretch'}}>
          <StatCard 
            name='Cost per Landing Page View'
            desciption='Landing Page Views are counted only when the page loads. A large cost difference between Outbound Clicks and Landing Page View suggests slow page loading.'
            remark={kpi_scores['Cost per Landing Page View']['status']}
            prefix='$'
            userScore={kpi_scores['Cost per Landing Page View']['user']}
            compScore={kpi_scores['Cost per Landing Page View']['competitor']} 
          />

          <StatCard
            name='Cost per Add to Cart'
            desciption='The cost currently required to generate one Add to Cart. Add to Cart is a valuable initial engagement from a visitor showing purchase intent. Great audience to retarget against.'
            remark={kpi_scores['Cost per Add to Cart']['status']}
            prefix='$'
            userScore={kpi_scores['Cost per Add to Cart']['user']}
            compScore={kpi_scores['Cost per Add to Cart']['competitor']} 
          />

          <StatCard
            name='Visitor to Purchase Conversion Rate'
            desciption='This is a major indicator helping you understand the quality of traffic you are buying (if compared against Conversion Rate from other traffic sources).'
            remark={kpi_scores['Visitor to Purchase Conversion Rate']['status']}
            suffix='%'
            userScore={kpi_scores['Visitor to Purchase Conversion Rate']['user']}
            compScore={kpi_scores['Visitor to Purchase Conversion Rate']['competitor']} 
          />
        </div>
        <div className='d-flex justify-content-center flex-container mt-4' style={{alignItems: 'stretch'}}>
          <StatCard
            name='Visitor to Add to Cart Conversion Rate'
            desciption='If you see a low Rate from Visitor to Add To Cart it’s a major indicator you are buying traffic with little to no purchase intent.'
            remark={kpi_scores['Visitor to Add to Cart Conversion Rate']['status']}
            suffix='%'
            userScore={kpi_scores['Visitor to Add to Cart Conversion Rate']['user']}
            compScore={kpi_scores['Visitor to Add to Cart Conversion Rate']['competitor']}
          />

          <StatCard 
            name='Add to Cart to Purchase Rate'
            desciption='If you see a big drop here, although it can be an indicator of traffic quality, it typically has to do with a poorly optimized checkout process on your website.'
            remark={kpi_scores['Add to Cart to Purchase Rate']['status']}
            suffix='%'
            userScore={kpi_scores['Add to Cart to Purchase Rate']['user']}
            compScore={kpi_scores['Add to Cart to Purchase Rate']['competitor']} 
          />

          <StatCard
            name='Visitor to Lead Conversion Rate'
            desciption='This is a major indicator helping you understand the quality of traffic you are buying (if compared against Conversion Rate from other traffic sources).'
            remark={kpi_scores['Visitor to Lead Conversion Rate']['status']}
            suffix='%'
            userScore={kpi_scores['Visitor to Lead Conversion Rate']['user']}
            compScore={kpi_scores['Visitor to Lead Conversion Rate']['competitor']} 
          />
        </div>
      </div>

      <div className='mt-4'>
        <Tips tips={results['Suggestions']['Funnel Score']} />
      </div>

      <div className='mt-4 mb-5'>
        <div className='my-2' style={{backgroundColor: '#FBF3EA', maxWidth: '846px', borderRadius: '1.5rem', boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
          <h1 className='pt-2' style={{font: 'sans-serif', margin: '0 20% 1% 20%', fontSize: '24px', fontWeight: 'bold'}}>Improve your score after a 30-minute call - alone or with us by your side.</h1>
          <p className='mx-5' style={{font: 'sans-serif', fontSize: 'medium'}}>Jumping on a call will allow us to better understand the “why” behind your performance. Why is your CTR too low? Let’s look at your ads. Why is your CPM so high? Let’s look at your audience sizes. We'll investigate these and many more questions during our (again, free) 1-on-1 session :)</p>
          <button className="btn mt-2" onClick={handleCTA} style={{backgroundColor: '#ed3e63', color: 'white' , borderRadius: '2rem'}}>Book Audit Call To Improve Score <IoMdArrowRoundForward size={20} /></button>
          <div className='my-3 pb-2 d-flex justify-content-center align-items-center'>
            <p className='mx-1' style={{marginBottom: '0px'}}>Available for Calls:</p>
            <img style={{borderRadius: '1rem'}} alt='' width={25} height={25} src='/chris.png' />
            <img style={{borderRadius: '1rem'}} alt='' width={25} height={25} src='/ahmad.png' />
          </div>
        </div>
      </div>
    </div>
  </div>
)}

export default ResultsPage;
