import React from 'react'

const StatCard = ({name, desciption, remark, userScore, compScore, prefix = '', suffix = ''}) => {
  if (suffix == "%") {
    userScore *= 100
    compScore *= 100
  }

  var userBar = 4
  var compBar = 70
  const formatScore = (score) => {
    if (score == "N/A") {
      return score
    }

    const num = Number(score);
  
    if (num % 1 === 0 && num < 1000) {
      return num;
    }
  
    const [integerPart, decimalPart] = num.toString().split('.');

    if (!decimalPart && integerPart.length <= 3) {
      return num;
    }
  
    if (integerPart.length >= 3) {
      return parseInt(integerPart, 10);
    }
  
    const allowedDecimalPlaces = 3 - integerPart.length;
    return parseFloat(num.toFixed(allowedDecimalPlaces));
  };

  if (userScore !== "N/A") {
    userBar = parseInt((userScore/(compScore+userScore))*120)
    compBar = parseInt((compScore/(compScore+userScore))*120)
  } else {
    prefix = ''
    suffix = ''
  }
  const remarks_hash = {'Strong': '#05FF00', 'Solid': '#05FF00', 'Average': '#FFE500', 'Poor': '#ED3E63', 'Fair': '#ED3E63', "N/A": '#575757'}
  const userBarColor = remarks_hash[remark]

  return(
    <div className='my-2 mx-3' style={{backgroundColor: 'white', width: '250px', minHeight: '300px', borderRadius: '1.5rem', textAlign: 'center', boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <div>
          <p className='pt-3 mx-3' style={{fontSize: 'medium', marginBottom: 0}}>{name}</p>
          <span style={{fontSize: 'medium'}}>{remark}</span>
        </div>
        <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
          <div className='my-2 d-flex justify-content-around align-items-end' style={{width: '150px'}}>
            <div><p style={{margin: '0px', fontSize: '16px'}}>{prefix}{formatScore(userScore)}{suffix}</p>
              <div className='mx-2' style={{backgroundColor: userBarColor, height: `${userBar}px`, width: '40px'}} />
            </div>
            <div><p style={{margin: '0px', fontSize: '16px'}}>{prefix}{formatScore(compScore)}{suffix}</p>
              <div className='mx-2' style={{backgroundColor: '#575757', height: `${compBar}px`, width: '40px'}} />
            </div>
          </div>
          <div className='d-flex justify-content-around' style={{width: '150px'}}>
            <p style={{marginLeft: '20px'}}>You</p>
            <p style={{marginLeft: '20px'}}>Competitors</p>
          </div>
        </div>
      </div>
      <p className='mx-3 p-in-stat' style={{fontSize: '9px', font: 'sans-serif', marginBottom: '10px'}}>{desciption}</p>
    </div>
  )
};

export default StatCard;
