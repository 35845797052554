import Box from "@mui/material/Box";
import React, { useEffect, useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { MdOutlineVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { LuMail } from "react-icons/lu";
import { SlLogin } from "react-icons/sl";
import { ROUTE_LOGIN, ROUTE_PRIVACY_POLICY } from "./constants";
import ConnectAds from "./ConnectAdsPage";


const Signup = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState(1);
  const [haveToken, setHaveToken] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && token !== '') {
      navigate('/chatbot')
    }
  }, [])

  const responseFacebook = (response) => {
    if (response.accessToken) {
      const graphApiVersion = 'v18.0';
      const appId = '105616075874993'; 
      const appSecret = 'f7254f1633ee8645491c2a676ec48302'; 
      const accessToken = response.accessToken;

      fetch(`https://graph.facebook.com/${graphApiVersion}/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          localStorage.setItem('token', data.access_token);
          setHaveToken(true);
          setTab(2);
        })
        .catch(error => {
          console.error('Error:', error);
      });
    } else {
      console.log(response)
    }
  }

  return (
    <div className="d-flex pt-sans justify-content-center align-items-center flex-column">
      {tab === 1 && <div style={{alignSelf: 'center'}}>
        <img className='my-4' width={180} height={44} src="/d.png" />
        <h2 className="pt-serif">Start your free trial.</h2>
        <h2 className="pt-serif">No credit card required.</h2>
        <div className="my-4 d-flex align-items-center flex-column">
          <FacebookLogin
            appId="105616075874993"
            fields="name,email"
            scope="public_profile,email,pages_show_list,pages_read_engagement,read_insights,pages_read_user_content,pages_manage_engagement,ads_read,ads_management,business_management,pages_manage_metadata,pages_messaging,pages_manage_posts,pages_manage_ads,leads_retrieval"
            autoLoad={false}
            callback={responseFacebook}
            render={renderProps => (
              <div className="d-flex justify-content-center align-items-center " onClick={renderProps.onClick} style={{backgroundColor: '#4267B2', height: '50px', borderRadius: '0.7rem', cursor: 'pointer'}} >
                <Box style={{backgroundColor: 'white', height: '80%', marginLeft: '5px', borderRadius: '5px'}}>
                  <FaFacebookF size={30} style={{ color: '#0866FF', margin: '0 6px', paddingTop: '6px'}} />
                </Box>
                <button className="btn" style={{color: 'white'}}>Continue with Facebook</button>
              </div>
            )}
          />
          <div className="d-flex mt-4 mb-3">
            <svg style={{margin: 'auto 0'}} height="3">
              <line x1="60%" y1="0" x2="100%" y2="0" stroke="black" strokeWidth="3"/>
            </svg>
              <div className="mx-2">Or</div>
            <svg style={{margin: 'auto 0'}} height="3">
              <line x1="0" y1="0" x2="40%" y2="0" stroke="black" strokeWidth="3"/>
            </svg>
          </div>
          <div style={{textAlign: 'left'}}>
            <span style={{fontSize: '14px'}} className="mb-2">Email</span><br/>
            <Input type="text" style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Email"
              value={email} onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={{textAlign: 'left'}} className="mt-3">
            <span style={{fontSize: '14px'}} className="mb-2">Password</span><br/>
            <Input type={showPass ? 'password' : 'text'} style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Password"
              value={password} onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end"
                  >
                    {showPass ? <MdVisibilityOff /> : <MdOutlineVisibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <button className="btn my-3" onClick={() => setTab(2)} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem'}}>Continue with Email <LuMail className="mx-2" size={22} /></button>
          <div className="mb-5">
            Already have an account?
            <SlLogin className="mx-2" style={{cursor: 'pointer'}} color="#ed3e63" size={20} onClick={() => navigate(ROUTE_LOGIN)} />
            <a style={{color: '#ed3e63', cursor: 'pointer'}} onClick={() => navigate(ROUTE_LOGIN)}>Log in</a>
          </div>
          <a className='m-0' style={{maxWidth: '350px', fontSize: '16px', textDecoration: 'none', color: 'black'}}>
            By clicking one of the buttons above, you agree to our&nbsp;
            <a style={{color: '#ed3e63', textDecoration: 'underline', cursor: 'pointer'}}>Terms and Conditions</a>
            &nbsp;and confirm that you have read our&nbsp;
            <a onClick={() => navigate(ROUTE_PRIVACY_POLICY)} style={{color: '#ed3e63', textDecoration: 'underline', cursor: 'pointer'}}>Privacy Policy</a>.
          </a>
        </div>
      </div>}
      {tab === 2 && <ConnectAds step={1} haveToken={haveToken} />}
    </div>
  )
}

export default Signup;
