export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_CHATBOT = '/chatbot';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_FORGET_PASSWORD = '/forget_password';
export const ROUTE_META_ADS_GRADER = '/meta-ads-grader';
export const ROUTE_META_ADS_GRADER_KPIS = '/meta-ads-grader/kpis';
export const ROUTE_META_ADS_GRADER_RESULTS = '/meta-ads-grader/results';
export const ROUTE_META_ADS_GRADER_BUSINESS = '/meta-ads-grader/business';
export const ROUTE_META_ADS_GRADER_LOADING_RESULTS = '/meta-ads-grader/loading-results';
export const ROUTE_NOT_FOUND = '*';
