import React from "react";
import { ROUTE_HOME } from "./constants";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {

  const navigate = useNavigate();
  return(
    <div className="mx-4 pb-4 d-flex pt-sans justify-content-center align-items-center flex-column" style={{height: '95vh'}}>
      <img style={{width: '10vw', minWidth: '160px'}} src="/confused_amigo.png" />
      <h1 className="pt-serif">Whooops!</h1>
      <p className="m-0 fs-5">Seems like this page does not exist. You can below to get directed back to the AdAmigo AI homepage.</p>
      <button className="btn my-4" style={{ backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem' }} onClick={() => {navigate(ROUTE_HOME)}}>Return to home Page</button>
    </div>
  );
};

export default NotFoundPage;
