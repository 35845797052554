import React from 'react'
import { FcIdea } from 'react-icons/fc';

const Tips = ({tips}) => {

  return(
    <div className='my-2' style={{backgroundColor: 'white', maxWidth: '846px', borderRadius: '1.5rem', boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
      <div className='mx-4'>
        <div className='pt-2 d-flex align-items-center'>
          <div className=''><FcIdea size={20} /></div>
          <h2 style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '0px'}} className='mx-1'>AI Generated Tips</h2>
          <p style={{fontSize: 'medium', marginBottom: '0px'}}> to improve your Performance Score</p>
        </div>
        <p className='mt-2 mx-1' style={{font: 'sans-serif', textAlign: 'left', fontSize: 'medium'}}>{tips}</p>
      </div>
      <div className='d-flex justify-content-end align-items-center mx-4' style={{font: 'sans-serif', textAlign: 'right', color: 'gray', fontSize: '12px'}}>
        Insights generated by
        <img className='mx-1' width={60} height={15} alt='' src='/d.png' />
      </div>
    </div>
  )
};

export default Tips;
