import './SettingsPage.scss';
import React, { useState } from "react";
import ConnectAds from './ConnectAdsPage';
import AccountSettings from './AccountSettings';
import { VscThreeBars } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CHATBOT, ROUTE_PRIVACY_POLICY } from './constants';
import { Avatar } from '@mui/material';
import { IoMdChatboxes } from "react-icons/io";
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const SettingsPage = () => {
  const navigate = useNavigate()

  const [tab, setTab] = useState(1);
  const [isToggled, setIsToggled] = useState(document.body.dataset.theme === 'dark' ? true : false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleDarkMode = () => {
    document.body.dataset.theme = isToggled ? 'light' : 'dark';
    setIsToggled(!isToggled);
  }

  const handleLogout = () => {
    localStorage.setItem('token', '')
    navigate('/login')
  }

  return (
    <>
    <div className="settings-page-container"><div className="settings-page">
      <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between', paddingTop: '16px'}}>
        <div className="d-flex align-items-center">
          <img className="mx-3 header-image" alt="" src="/d.png" />
          <div className="toggle-sidebar">
            <VscThreeBars className="chatbot-header-icon" onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <AiOutlineQuestionCircle className="chatbot-header-icon" onClick={() => navigate(ROUTE_PRIVACY_POLICY)} style={{marginRight: '10px', cursor: 'pointer'}} />
          <IoMdChatboxes className="chatbot-header-icon" onClick={() => navigate(ROUTE_CHATBOT)} style={{marginRight: '20px', cursor: 'pointer'}} />
          <Avatar className="avatar-image" alt="" src={localStorage.getItem('profile_pic') || '/fallback_image.jpg'} />
        </div>
      </div>
      <div className="chat-container">
        <div className={`d-flex align-items-stretch settings-sidebar ${isSidebarVisible ? 'visible' : ''}`}>

          <div style={{ width: '248px', marginBottom: '1em', marginTop: '10px', height: '100vh'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '5px'}}>
              Settings
              <div className="toggle-sidebar">
                <VscThreeBars size={20} color='black' onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
              </div>
            </div>

            <ul className='my-2' style={{color: 'black'}}>
              <li className={`${tab == 1 ? 'active' : ''}`} onClick={() => setTab(1)} >Meta Ads Settings</li>
              <li className={`${tab == 2 ? 'active' : ''}`} onClick={() => setTab(2)} >Account Setting</li>
              <li className={`${tab == 3 ? 'active' : ''}`} onClick={() => setTab(3)} >Theme Setting</li>
              <li onClick={handleLogout} >Log Out</li>
            </ul>
          </div>
        </div>
        <div className="main-content-container">
          <div className="settings-main">
            <div className='mx-2' style={{flex: 1, display: 'flex', justifyContent: 'center', width: '100%', height: '100%'}}>
              <div className='my-2' style={{height: '100%'}}>
                {tab === 1 && <ConnectAds step={2} />}
                {tab === 2 && <AccountSettings />}
                {tab === 3 && <div className="d-flex align-items-center justify-content-center">
                  Dark Theme:&nbsp;&nbsp;
                  <div className="toggle-switch" onClick={toggleDarkMode}>
                    <div className={`slider ${isToggled ? 'toggled' : ''}`}></div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>


    </div></div>

    {/* <div className="d-flex pt-sans">
      <div className={`d-flex align-items-stretch settings-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
        <div className='d-flex justify-content-around align-items-center'>
          <h2 className='pt-3' style={{color: 'white'}}>Settings</h2>
          <div className="pt-2 toggle-sidebar">
            <VscThreeBars size={20} color='white' onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
          </div>
        </div>
        <button className="btn mb-4" onClick={() => setTab(1)} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem', width: '90%', margin: '0 auto'}}>Meta Ads Settings</button>
        <button className="btn mb-4" onClick={() => setTab(2)} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem', width: '90%', margin: '0 auto'}}>Account Setting</button>
        <button className="btn mb-4" onClick={() => setTab(3)} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem', width: '90%', margin: '0 auto'}}>Theme Setting</button>
        <button className="btn mb-4" onClick={handleLogout} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem', width: '90%', margin: '0 auto'}}>Log Out</button>
      </div>

      <div className='mx-2' style={{flex: 1, display: 'flex', justifyContent: 'center', width: '100%'}}>
        <div className="pt-2 toggle-sidebar">
        </div>
        <div>
          <div className='d-flex my-4 justify-content-center align-items-center' style={{width: '100%'}}>
            <VscThreeBars size={20} className='toggle-sidebar' onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
            <img className='mx-4' alt='logo' width={180} height={44}  src="/d.png" />
          </div>
          {tab === 1 && <ConnectAds step={2} />}
          {tab === 2 && <AccountSettings />}
          {tab === 3 && <div className="d-flex align-items-center justify-content-center">
            Dark Theme:&nbsp;&nbsp;
            <div className="toggle-switch" onClick={toggleDarkMode}>
              <div className={`slider ${isToggled ? 'toggled' : ''}`}></div>
            </div>
          </div>}

        </div>
      </div>
    </div> */}
    </>
  );
};

export default SettingsPage;
