import React, { useRef, useEffect, useContext } from "react";

import { Input } from "@mui/material";
import { IoMdArrowRoundForward } from 'react-icons/io';
import { toast, ToastContainer } from 'react-toastify';
import { AdsGraderContext } from './AdsGraderContext';
import { useNavigate } from 'react-router-dom';

// import ReactPixel from 'react-facebook-pixel';
import Header from "./shared/Header";
import PrivacyTag from "./shared/PrivacyTag";
import 'react-toastify/dist/ReactToastify.css';
import { initFacebookPixel, trackEvent } from "./shared/metaPixel";

const LandingPage = () => {
  const navigate = useNavigate();
  const { userEmail, setUserEmail } = useContext(AdsGraderContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.textContent = `gtag('event', 'conversion', {'send_to': 'AW-11393483668/tXesCL7W0fUYEJSH67gq'});`;
    document.head.appendChild(script);

    initFacebookPixel('PageView');
  }, []);

  const userEmailRef = useRef(null);

  const scrollToTopAndFocusInput = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    userEmailRef.current.children[0].focus();
  };

  const handlenext = () => {
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
    if (emailRegex.test(userEmail)) {
      trackEvent('EmailSubmitted', { buttonName: 'Get Free grade' });
      navigate("/meta-ads-grader/business")
    } else {
      toast.error('Please enter a valid Email')
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handlenext();
    }
  };

  return(
    <div className="pt-sans" style={{ display: 'flex', backgroundColor: '#FBF3EA', justifyContent: 'center'}}>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
      <div className="landing-page-width">
        <Header />
        <div className="d-flex align-items-start justify-content-between flex-container">
          <div className="half-width" style={{textAlign: 'left'}}>
            <h1 className="pt-serif h1-font1" >How do your Meta Ads stack up against your competitors?</h1>
            <p className="p-font1" style={{padding: '10px 0'}}>Find out where you are over and under performing and get actionable insights to scale your account - in minutes, for free.</p>
            <div style={{width: '70%'}}>
              <Input ref={userEmailRef} onChange={(e) => setUserEmail(e.target.value)} onKeyDown={handleKeyDown} value={userEmail} placeholder="Enter you email" style={{fontSize: '16px', padding: '8px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} fullWidth /><br></br>
            </div>
            <button className="btn my-3" style={{backgroundColor: '#ed3e63',color: 'white',borderRadius: '18px',border: 'none',padding: '7px 65px',fontWeight: 'bold',cursor: 'pointer',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',transition: 'background-color 0.3s',}} onClick={handlenext}>Get FREE Grade <IoMdArrowRoundForward size={20} /></button>
            <div className="privacy-tag-width">
              <PrivacyTag fontSize='12px' mwidth="100%" classN='align-items-center'/>
            </div>
          </div>
          <img className="demo-photo" src="/p.png" alt=""/>
        </div>
        <div className="mt-4">
          <p style={{fontSize: '0.9rem', color: 'grey'}}>World-class brands and agencies have already received their grade</p>
          <div className="image-container">
            <img aspectRatio={1.66} width={75} alt="" src="/e.png"/>
            <img aspectRatio={1.5} width={90} alt="" src="/g.png"/>
            <img aspectRatio={5.2} width={125} alt="" src="/f.png"/>
            <img aspectRatio={4.1} width={125} alt="" src="/c.png"/>
            <img aspectRatio={3.66} width={110} alt="" src="/h.png"/>
            <img aspectRatio={1.66} width={50} alt="" src="/i.png"/>
          </div>
        </div>
        <div className="mt-5 my-2">
          <div className="weak-spots-max-width" style={{ marginLeft: 'auto', marginRight: 'auto'}}>
            <h1 className="pt-serif h1-font2">Unlock weak spots and get actionable tips to improve your Meta ads - without paying a cent</h1>
            <p className="p-font4">
              Designed for agencies and small businesses, our Free Meta Ads Grader
              reveals your KPI performance compared to you competitors and offer
              actionable insights to improve your weak KPIs. We focus on KPIs that
              actually matter such as Revenue, ROAS, Cost per Lead and more.
            </p>
            <iframe src="https://app.claap.io/chris/c-tWodr6hdLx-1sumcjDIz-XU/embed" style={{ width: '100%', aspectRatio: '1.5'}} />
            <h1 className="pt-serif" style={{fontSize: '35px'}}>Stop guessing - get your grade</h1>
            <p style={{fontSize: '0.9rem'}}>Get your detailed report now and implement actionable insights to improve your Meta Ads performance!</p>
          </div>
        </div>

        <div className="mt-4 mb-5 d-flex justify-content-center align-items-center  flex-container">
          <img className="mx-5 demo-photo2" alt="" src="/y.png" />
          <ul className="mx-5" style={{flexShrink: 0, textAlign: 'left', paddingLeft: '0', listStyleType: 'none' }}>
            <li style={{ background: `url('k.png') no-repeat left center`, backgroundSize: '10px 10px',paddingLeft: '20px',fontSize: '1.1rem', marginBottom: '10px' }}>
              Submit a few key metrics; we'll generate a comprehensive report in seconds.</li>
              <li style={{ background: `url('k.png') no-repeat left center`, backgroundSize: '10px 10px',paddingLeft: '20px',fontSize: '1.1rem', marginBottom: '10px' }}>
              Focus on crucial KPIs like Revenue, ROAS, and Cost per Lead.</li>
              <li style={{ background: `url('k.png') no-repeat left center`, backgroundSize: '10px 10px',paddingLeft: '20px',fontSize: '1.1rem', marginBottom: '10px' }}>
              Compare your creative strategy with competitors to refine your approach.</li>
              <li style={{ background: `url('k.png') no-repeat left center`, backgroundSize: '10px 10px',paddingLeft: '20px',fontSize: '1.1rem', marginBottom: '10px' }}>
              Evaluate if your advertising costs are aligned with industry standards.</li>
              <li style={{ background: `url('k.png') no-repeat left center`, backgroundSize: '10px 10px',paddingLeft: '20px',fontSize: '1.1rem', marginBottom: '10px' }}>
              Assess the quality of traffic driven by your ads.</li>
              <li style={{ background: `url('k.png') no-repeat left center`, backgroundSize: '10px 10px',paddingLeft: '20px',fontSize: '1.1rem', marginBottom: '10px' }}>
              Receive expert insights to catch up with your competition</li>
          </ul>
        </div>

        <div style={{backgroundColor: '#ed3e63', position: 'sticky', alignSelf: 'left'}}>
          <div style={{ maxWidth: '70vw', marginLeft: 'auto', marginRight: 'auto', padding: '2rem 1rem', textAlign: 'center' }}>
            <h1 className="pt-serif h1-font3" style={{ color: 'white', marginLeft: 'auto', marginRight: 'auto',}}>
              Discover how you measure up against your competitors and receive a personalized checklist to improve your weak spots— all centered around KPIs that truly matter.
            </h1>
            <div onClick={scrollToTopAndFocusInput} style={{ cursor: 'pointer', borderRadius: '1.5rem',backgroundColor: '#FBF3EA', maxWidth: '400px',marginLeft: 'auto',marginRight: 'auto',padding: '10px',marginTop: '20px'}}>
              <b style={{ color: '#ed3e63' }}>Get Your Grade - 100% Free & Secure</b>
            </div>
          </div>
        </div>

        <div className="mt-5" style={{display: 'flex', justifySelf: 'start', alignItems: 'flex-end'}}>
          <img className="header-image" style={{ marginBottom:'10px' }} alt="" src="/d.png" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <p style={{ margin: '0' }}>AdAmigo.ai Inc.</p>
              <p style={{ margin: '0' }}>1111B S Governors Ave STE 7393</p>
              <p style={{ margin: '0' }}>Dover, Delaware 19904</p>
              <p style={{ margin: '0' }}>Kent County</p>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end', fontSize: '12px', color: 'grey' }}>
            <a >Privacy Policy</a>
            <a className="terms-margin">Terms & conditions</a>
            <a >Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LandingPage;
