import React, { useState } from 'react';
import { Input } from '@mui/material';

const AccountSettings = () => {

  const [newPass, setNewPass] = useState('');
  const [currentPass, setCurrentPass] = useState('');
  const [confNewPass, setConfNewPass] = useState('');

  return (
    <div>
      <h2>Change your password</h2>
      <div style={{textAlign: 'left'}}>
        <span style={{fontSize: '14px'}} className="mb-2">Current Password</span><br/>
        <Input type="text" style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type your Current Password"
          value={currentPass} onChange={(e) => setCurrentPass(e.target.value)}
        />
      </div>
      <div style={{textAlign: 'left'}}>
        <span style={{fontSize: '14px'}} className="mb-2">New Password</span><br/>
        <Input type="text" style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type your New Password"
          value={newPass} onChange={(e) => setNewPass(e.target.value)}
        />
      </div>
      <div style={{textAlign: 'left'}}>
        <span style={{fontSize: '14px'}} className="mb-2">New Password</span><br/>
        <Input type="text" style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Confirm your New Password"
          value={confNewPass} onChange={(e) => setConfNewPass(e.target.value)}
        />
      </div>
      <button className="btn my-2" style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem'}}>Reset Password</button>
    </div>
  );
};

export default AccountSettings;
