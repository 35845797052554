import React, { useContext, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import 'react-toastify/dist/ReactToastify.css';

import Header from "./shared/Header";
import PrivacyTag from "./shared/PrivacyTag";
import ReviewCard from "./shared/ReviewCard";

import { GoDotFill } from 'react-icons/go';
import { AiFillCheckCircle } from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdArrowRoundForward } from "react-icons/io";
import { AdsGraderContext } from './AdsGraderContext';
import { useNavigate } from "react-router-dom";
import { initFacebookPixel, trackEvent } from "./shared/metaPixel";

const SurveyPage = () => {

  const {
    businessType, setBusinessType,
    businessVertical, setBusinessVertical,
    advertisingGoal, setAdvertisingGoal,
    advertisedContinents, setAdvertisedContinents,
  } = useContext(AdsGraderContext);

  const navigate = useNavigate();
  const businessVerticalDict = {
    "Local Business": [
      "Bank",
      "Bar",
      "Book Store",
      "Concert Venue",
      "Food / Grocery",
      "Hotel",
      "Local Business",
      "Movie Theatre",
      "Museum/Art Gallery",
      "Outdoor Gear/Sporting Goods",
      "Real Estate",
      "Restaurant / Cafe",
      "School",
      "Shopping / Retail",
      "Spas/Beauty/Personal Care",
      "Other"
    ],
    "Company": [
      "Automobiles and Parts",
      "Church",
      "Computers/Technology",
      "Consulting/Business Services",
      "Cause",
      "Food/Beverages",
      "Health/Beauty",
      "Insurance Company",
      "Internet/Software",
      "Legal/Law",
      "Non-Profit Organization",
      "Retail and Consumer Merchandise",
      "Media/News/Publishing",
      "Travel/Leisure",
      "Other"
    ],
    "Brand": [
      "App",
      "Appliance",
      "Baby Goods/Kids Goods",
      "Cars",
      "Clothing",
      "Electronics",
      "Food/Beverages",
      "Furniture",
      "Games/Toys",
      "Health/Beauty",
      "Jewelry/Watches",
      "Kitchen/Cooking",
      "Pet Supplies",
      "Vitamins/Minerals",
      "Other"
    ],
    "Public Figure": [
      "Actor/Director",
      "Artist",
      "Athlete",
      "Author",
      "Business Person",
      "Chef",
      "Coach",
      "Doctor",
      "Entertainer",
      "Journalist",
      "Lawyer",
      "Musician/Band",
      "Politician",
      "Teacher",
      "Writer",
      "Other"
    ],
    "Entertainment": [
      "Album",
      "Book",
      "Concert Tour",
      "Library",
      "Magazine",
      "Movie",
      "Radio Station",
      "Record Label",
      "Sports Venue",
      "TV Channel",
      "TV Show",
      "Other"
    ]
  };

  const bussinessTypeList = Object.keys(businessVerticalDict);
  const advertisingGoalList = ["Generate Leads", "Generate Purchases", "Generate App Installs", "Generate Downloads"]
  const continents = [
    "North America",
    "Europe",
    "Asia",
    "South America",
    "Australia",
    "Africa",
  ];


  const handleSelect = (selectedValue) => {
    if (advertisedContinents.includes(selectedValue)) {
      setAdvertisedContinents(advertisedContinents.filter((value) => value !== selectedValue));
    } else {
      setAdvertisedContinents([...advertisedContinents, selectedValue]);
    }
  };

  const validateAndProceed = () => {
    trackEvent('BusinessInfoSubmitted');
  
    const errorMessages = [
      !businessType && "Business type is required.",
      !businessVertical && "Business Vertical is required.",
      !advertisingGoal && "Advertising Goal is required.",
      advertisedContinents.length === 0 && "Advertised Continents are required."
    ].filter(Boolean);
  
    if (errorMessages.length) {
      errorMessages.forEach(toast.error);
      return;
    }
  
    navigate('/meta-ads-grader/kpis')
  };

  return (
    <div className="pt-sans " style={{ display: 'flex', backgroundColor: '#FBF3EA', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div style={{ width: '100%', maxWidth: '1500px'}}>
      <div className="survey-page-margins">
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
        <Header />
        <div class="progress-container" style={{width: '30%', minWidth: '200px'}} >
          <div class= "circle active" onClick={() => navigate("/meta-ads-grader")}><AiFillCheckCircle size={50} color="#ed3e63" /></div>
          <div />
          <div class="circle active"><GoDotFill size={50} color="#ed3e63" /></div>
          <div className="progress-2"></div>
          <div class="circle"></div>
          <div />
          <div class="circle"></div>
        </div>
        <div className="progress-container-copy my-0" style={{ width: '35%', minWidth: '280px'}}>
          <p style={{paddingLeft: '2rem'}}>Contact</p>
          <p style={{paddingLeft: '0.8rem'}}>Business</p>
          <p style={{paddingLeft: '1.4rem'}}>Metrics</p>
          <p style={{paddingRight: '1.4rem'}}>Get Your Report</p>
        </div>
        <div>
          <div className="mt-4 page_goal">
            <h1 className="pt-serif h1-font4" >Tell us about your business:</h1>
            <p className="p-font2" >This will help us compare your performance to your competitors. Fields without an * are optional.</p>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-container">
            <div className="width-45">
              <div className="mb-2" style={{textAlign: 'left'}}>
                <h6 className="mb-2"><span className="text-danger">*</span>Business type:</h6>
                <DropdownButton id="dropdown-menu"
                    title={businessType || "Select your Business Type"}
                    onSelect={(e) => {setBusinessType(e); setBusinessVertical('');}}
                    variant={"secondary"}
                  >
                  {Object.values(bussinessTypeList).map((value) => {
                    return (<Dropdown.Item key={value} eventKey={value}>{value}</Dropdown.Item>);
                  })}
                </DropdownButton>
              </div>
              <div style={{textAlign: 'left'}}>
                <h6 className="mb-2"><span className="text-danger">*</span>Business Vertical:</h6>
                <DropdownButton id="dropdown-menu"
                  title={businessVertical || "Select your Business Vertical"}
                  onSelect={(e) => setBusinessVertical(e)}
                  variant={"secondary"}
                  >
                  {Object.values(businessVerticalDict[businessType] || {}).map((value) => {
                    return (<Dropdown.Item key={value} eventKey={value}>{value}</Dropdown.Item>);
                  })}
                </DropdownButton>
              </div>
            </div>

            <div className="width-45" style={{textAlign: 'right'}}>
              <div className="mb-2" style={{textAlign: 'left'}}>
                <h6 className="mb-2"><span className="text-danger">*</span>Advertising Goal:</h6>
                <DropdownButton id="dropdown-menu"
                  title={advertisingGoal || "Select your Advertising Goal"}
                  onSelect={(e) => setAdvertisingGoal(e)}
                  variant={"secondary"}
                  >
                  {Object.values(advertisingGoalList).map((value) => {
                    return (<Dropdown.Item key={value} eventKey={value}>{value}</Dropdown.Item>);
                  })}
                </DropdownButton>
              </div>
              <div style={{textAlign: 'left'}}>
                <h6 className="mb-2"><span className="text-danger">*</span>Advertised Continents:</h6>
                <DropdownButton id="dropdown-menu"
                  title={advertisedContinents.length === 0 ? "Select your Advertised Continents" : advertisedContinents.join(', ')}
                  onSelect={(e) => handleSelect(e)}
                  variant={"secondary"}
                  >{Object.values(continents).map((value) => {
                    return (<Dropdown.Item key={value} eventKey={value}>{value}</Dropdown.Item>);
                  })}
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
        <button className="btn mt-5 mb-4" style={{backgroundColor: '#ed3e63',color: 'white',borderRadius: '18px',border: 'none',padding: '7px 65px',fontWeight: 'bold',cursor: 'pointer',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',transition: 'background-color 0.3s',}} onClick={validateAndProceed}>Go To Next Step <IoMdArrowRoundForward size={20} /></button>
        <PrivacyTag fontSize='small' />
        <div className="mt-4 pt-2" maxWidth='fit-content' >
          <div className="mx-5 mb-5 mt-4 d-flex justify-content-center flex-container">
            <ReviewCard 
              image='/q.jpeg' 
              name='Lukas' 
              tag='Agency Co-Founder || ever spot' 
              review='Every month, I pull a new report for all our clients in our agency.
              The additional insights they provide to improve on underperforming KPIs are pure gold, and we easily apply 90% of them every time.'
              stars={5}
              date='Sept 19, 2023'
            />

            <ReviewCard 
              image='/o.jpg' 
              name='Thomas Katakis' 
              tag='The Hiring Room - CoFounder' 
              review="We've always been curious about our standing in the market compared to our competitors. Realizing that others in our industry excel in certain KPIs has driven home the message: there's no room for excuses when it comes to underperformance. Great audit!"
              stars={5}
              date='Aug 15, 2023'
            />

            <ReviewCard 
              image='/p.jpg'
              name='Sarah'
              tag='Founder of Belle Fever Jewellery'
              review='Wow! My Click Through Rates were less than half my competitor average.
              They shared incredible insights to improve my CTR! They should charge for it, honestly.'
              stars={5}
              date='July 23, 2023'
            />
          </div>
        </div>
      </div>
      </div>
      <div style={{ width: '100%', maxWidth: '1500px'}}>
        <div className="survey-page-margins" style={{ display: 'flex', alignItems: 'flex-end', fontSize: '12px', alignSelf: 'start' }}>
          <a >Privacy Policy</a>
          <a className="terms-margin">Terms & conditions</a>
          <a >Contact Us</a>
        </div>
      </div>
    </div>
  )
}

export default SurveyPage;
