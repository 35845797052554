import Box from "@mui/material/Box";
import React, { useEffect, useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { MdOutlineVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { LuMail } from "react-icons/lu";
import { SlLogin } from "react-icons/sl";
import { ROUTE_FORGET_PASSWORD, ROUTE_SIGNUP } from "./constants";


const Login = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && token !== '') {
      navigate('/chatbot')
    }
  }, [])

  const responseFacebook = (response) => {
    if (response.accessToken) {
      const graphApiVersion = 'v18.0';
      const appId = '105616075874993'; 
      const appSecret = 'f7254f1633ee8645491c2a676ec48302'; 
      const accessToken = response.accessToken;

      fetch(`https://graph.facebook.com/${graphApiVersion}/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          localStorage.setItem('token', data.access_token);
          navigate('/chatbot')
        })
        .catch(error => {
          console.error('Error:', error);
      });
    } else {
      console.log(response)
    }
  }

  return (
    <div className="d-flex pt-sans justify-content-center align-items-center flex-column">
      <div style={{alignSelf: 'center'}}>
        <img className='my-4' width={180} height={44} src="/d.png" />
        <h2 className="pt-serif">Great to see you again!</h2>
        <div className="my-4 d-flex align-items-center flex-column">
          <FacebookLogin
            appId="105616075874993"
            fields="name,email"
            scope="public_profile,email,pages_show_list,pages_read_engagement,read_insights,pages_read_user_content,pages_manage_engagement,ads_read,ads_management,business_management,pages_manage_metadata,pages_messaging,pages_manage_posts,pages_manage_ads,leads_retrieval"
            autoLoad={false}
            callback={responseFacebook}
            render={renderProps => (
              <div className="d-flex justify-content-center align-items-center " onClick={renderProps.onClick} style={{backgroundColor: '#4267B2', height: '50px', borderRadius: '0.7rem', cursor: 'pointer'}} >
                <Box style={{backgroundColor: 'white', height: '80%', marginLeft: '5px', borderRadius: '5px'}}>
                  <FaFacebookF size={30} style={{ color: '#0866FF', margin: '0 6px', paddingTop: '6px'}} />
                </Box>
                <button className="btn" style={{color: 'white'}}>Login with Facebook</button>
              </div>
            )}
          />
          <div className="d-flex mt-4 mb-3">
            <svg style={{margin: 'auto 0'}} height="3">
              <line x1="60%" y1="0" x2="100%" y2="0" stroke="black" strokeWidth="3"/>
            </svg>
              <div className="mx-2">Or</div>
            <svg style={{margin: 'auto 0'}} height="3">
              <line x1="0" y1="0" x2="40%" y2="0" stroke="black" strokeWidth="3"/>
            </svg>
          </div>
          <div style={{textAlign: 'left'}}>
            <span style={{fontSize: '14px'}} className="mb-2">Email</span><br/>
            <Input type="text" style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Email"
              value={email} onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={{textAlign: 'left'}} className="mt-3">
            <span style={{fontSize: '14px'}} className="mb-2">Password</span><br/>
            <Input type={showPass ? 'text' : 'password'} style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Password"
              value={password} onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end"
                  >
                    {showPass ? <MdVisibilityOff /> : <MdOutlineVisibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <button className="btn my-3" onClick={{}} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem'}}>Continue with Email <LuMail className="mx-2" size={22} /></button>
          <div className="mb-4" style={{color: '#ed3e63', cursor: 'pointer'}} onClick={() => navigate(ROUTE_FORGET_PASSWORD)}>
            Forgot Password?
          </div>
          <div>
            Don't have an account yet?
            <SlLogin style={{cursor: 'pointer'}} onClick={() => navigate(ROUTE_FORGET_PASSWORD)} className="mx-2" color="#ed3e63" size={20} />
            <a style={{color: '#ed3e63', cursor: 'pointer'}} onClick={() => navigate(ROUTE_SIGNUP)}>Sign up</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
