export const initFacebookPixel = (pageName) => {
  // Check if fbq (Facebook Pixel) function is already defined
  if (window.fbq) return;

  // Initialize the fbq function
  window.fbq = function() {
    window.fbq.callMethod ?
    window.fbq.callMethod.apply(window.fbq, arguments) : 
    window.fbq.queue.push(arguments);
  };

  // Setup the Pixel
  window.fbq.push = window.fbq;
  window.fbq.loaded = true;
  window.fbq.version = '2.0';
  window.fbq.queue = [];
  
  // Load the Facebook Pixel script
  let script = document.createElement('script');
  script.async = true;
  script.src = 'https://connect.facebook.net/en_US/fbevents.js';
  document.head.appendChild(script);

  // Initialize the Pixel with your Pixel ID
  window.fbq('init', '933153271437382');
  window.fbq('track', pageName);
};

export const trackEvent = (eventName, eventData = {}) => {
  window.fbq('track', eventName, eventData);
};
