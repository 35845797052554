import React, { createContext, useState } from 'react';

// Creating the context
export const AdsGraderContext = createContext();

// Provider component
export const AdsGraderProvider = ({ children }) => {
  const [userEmail, setUserEmail] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [businessVertical, setBusinessVertical] = useState('');
  const [advertisingGoal, setAdvertisingGoal] = useState('');
  const [advertisedContinents, setAdvertisedContinents] = useState([]);
  const [amount, setAmount] = useState('');
  const [revenue, setRevenue] = useState('');
  const [impressions, setImpressions] = useState('');
  const [clicks, setClicks] = useState('');
  const [outboundClicks, setOutboundClicks] = useState('');
  const [landingPageViews, setLandingPageViews] = useState('');
  const [addToCarts, setAddToCarts] = useState('');
  const [purchases, setPurchases] = useState('');
  const [leads, setLeads] = useState('');
  const [results, setResults] = useState(null);

  return (
    <AdsGraderContext.Provider value={{
      userEmail, setUserEmail,
      businessType, setBusinessType, businessVertical, setBusinessVertical,
      advertisingGoal, setAdvertisingGoal, advertisedContinents, setAdvertisedContinents,
      amount, setAmount, revenue, setRevenue, impressions, setImpressions,
      clicks, setClicks, outboundClicks, setOutboundClicks, landingPageViews, setLandingPageViews,
      addToCarts, setAddToCarts, purchases, setPurchases, leads, setLeads,
      results, setResults
    }}>
      {children}
    </AdsGraderContext.Provider>
  );
};
