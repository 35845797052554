import React, { useState } from 'react';
import Input from '@mui/material/Input';
import { SlLogin } from "react-icons/sl";
import { IoMdArrowRoundForward } from "react-icons/io";
import { ROUTE_FORGET_PASSWORD, ROUTE_SIGNUP } from './constants';
import { useNavigate } from 'react-router-dom';


const ForgetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  return (
    <div className="d-flex pt-sans justify-content-center align-items-center flex-column">
      <div style={{alignSelf: 'center'}}>
        <img className='my-4' width={180} height={44} src="/d.png" />
        <h2 className="pt-serif">Forgot your password?</h2>
        <p style={{color: 'black', textDecoration: 'none', maxWidth: '350px'}}>Don't worry! Just fill in your email and we'll send you a link to reset your password.</p>
        <div className="my-4 d-flex align-items-center flex-column">
          <div style={{textAlign: 'left'}}>
            <span style={{fontSize: '14px'}} className="mb-2">Email</span><br/>
            <Input type="text" style={{fontSize: '16px', padding: '6px 12px', border: '1px solid #ced4da', borderRadius: '4px', color: '#495057', margin: '5px 0'}} placeholder="Type in your Email"
              value={email} onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button className="btn my-3" onClick={{}} style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '2rem'}}>
            Send Reset Password Email <IoMdArrowRoundForward size={20} />
          </button>
          <div>
            Don't have an account yet?
            <SlLogin style={{cursor: 'pointer'}} onClick={() => navigate(ROUTE_FORGET_PASSWORD)} className="mx-2" color="#ed3e63" size={20} />
            <a style={{color: '#ed3e63', cursor: 'pointer'}} onClick={() => navigate(ROUTE_SIGNUP)}>Sign up</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword;
