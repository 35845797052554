import React, { useState, useEffect, useRef, useReducer } from "react";
import './ChatBot.scss';
import Markdown from 'markdown-to-jsx';
import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import { ImBin } from "react-icons/im";
import { IoMdAdd } from "react-icons/io";
// import { FaMapPin } from "react-icons/fa";
import { FiThumbsUp } from 'react-icons/fi';
import { FiSettings } from 'react-icons/fi';
import { BsThreeDots } from "react-icons/bs";
// import { VscPinned } from 'react-icons/vsc';
import { FiThumbsDown } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';
import { PiSignOutFill } from "react-icons/pi";
import { VscThreeBars } from "react-icons/vsc";
import { RiImageAddFill } from "react-icons/ri";
import { ImCancelCircle } from "react-icons/im";
import { RiSendPlane2Fill } from 'react-icons/ri';
import { BiSolidRightArrow } from 'react-icons/bi';
import { BsWindowSidebar } from "react-icons/bs"
import { toast, ToastContainer } from 'react-toastify';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { Avatar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap";
import { ROUTE_PRIVACY_POLICY, ROUTE_SETTINGS } from "./constants";

const Chatbot = () => {
  const divRef = useRef(null);
  const menuRef = useRef(null);
  const profileRef = useRef(null);
  const abortControllerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Adamigo - Chatbot';
    const token = localStorage.getItem('token');
    if (!token && token === '') {
      navigate('/login')
    } else {
      fetch(`https://graph.facebook.com/me?fields=picture.width(300).height(300)&access_token=${token}`)
        .then(response => response.json())
        .then(data => localStorage.setItem('profile_pic', data.picture.data.url))
        .catch((error) => {
          console.error('Error fetching profile picture:', error);
        });
    }
  }, [navigate])

  const urlLocation = window.location.href;
  let CHATBOT_BASE_URL
  if (urlLocation.includes('localhost') || urlLocation.includes('staging') || urlLocation.includes('test')) {
    if (urlLocation.includes('localhost')) {
      CHATBOT_BASE_URL = 'http://localhost:8000/chatbot';
    } else if(urlLocation.includes('staging')) {
      CHATBOT_BASE_URL = 'https://new-combine-chat.adamigo.ai/chatbot';
    } else {
      CHATBOT_BASE_URL = 'https://test-devps.adamigo.ai/chatbot';
    }
    localStorage.setItem('token', 'EAABgDqivYrEBOwO8cdBDY3L3YdZCJvFJbZCrEZBMHHD8ZADAjVMvYeWKXEJrZAAkIoJFJnicqXQGxwutdcDwj8V1gYj2yBgdZAg0DKx1AOV1hHZAmhoGU1FGBeu1Y8otqfdKxqmMV5FZCt1e3CtgJdPQqiv68RN71ng9RGdL0KiP3DGXf5SnjdEVj2DI');
  } else {
    CHATBOT_BASE_URL = 'https://adamigo-devps.adamigo.ai/chatbot';
  }

  const access_token = localStorage.getItem('token');

  const maxRows = 3;
  const [rowsCount, setRowsCount] = useState(1);
  const [canScroll, setCanScroll] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openMenuChatID, setOpenMenuChatID] = useState(null);
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [shouldMenuClose, setShouldMenuClose] = useState(false);
  const [isChatbotFrozen, setIschatbotFrozen] = useState(false);
  // const [responseJson, setResponseJson] = useState(null);
  // const [isToggled, setIsToggled] = useState(false);
  const [currentChatID, setCurrentChatID] = useState('');
  const [chats, setChats] = useState([]);
  const [adaccounts, setAdaccounts] = useState([])
  const [selectedAdaccountId, setSelectedAdaccountId] = useState('')
  const [isPopupOpen2, setPopupOpen2] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [openFileUrl, setOpenFileUrl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const initialState = { newMessage: '', hasFile: false, fileUrl: '', file_type: ''};
  const [inputParams, dispatch] = useReducer(reducer, initialState);

  const overrides = {
    p: {
      component: ({ children, ...props }) => <p style={{ fontSize: 'medium', marginBottom: '1px', marginTop: '5px' }} {...props}>{children}</p>
    },
    li: {
      component: ({ children, ...props }) => <li style={{ fontSize: 'medium' }} {...props}>{children}</li>
    },
    a: {
        component: ({ children, ...props }) => <a style={{ textDecoration: 'none', color: 'blue' }} {...props}>{children}</a>,
        props: {
            target: "_blank"
        }
    }
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'MESSAGE':
        return { ...state, newMessage: action.payload };
      case 'FILE_URL':
        return { ...state, fileUrl: action.payload.url, hasFile: action.payload.has_file, fileType: action.payload.file_type };
      default:
        return state;
    }
  }

  const variantValueSalesRegion = (value) => {
    return selectedAdaccountId === value ? "#ed3e63" : "#ddd";
  };

  const variantValueSalesRegion2 = (value) => {
    return selectedAdaccountId === value ? "white" : "black";
  };

  const addSign = (value) => {
    return value === "#ed3e63" ? (
      <IoMdAdd size={20} className="float-start text-white" />
    ) : (
      ""
    );
  };

  // function pinnedChats() {
  //   return chats.filter(chat => chat.pinned === true);
  // }
  
  // function unPinnedChats() {
  //   return chats.filter(chat => chat.pinned === false);
  // }

  const setShouldMenuCloseAfterDelay = () => {
    setTimeout(() => {
      setShouldMenuClose(false);
    }, 500);
  };

  const handleThreeDotsClick = (chat_id, event) => {
    if (!shouldMenuClose) {
      const rect = event.target.getBoundingClientRect();
      setOpenMenuChatID(openMenuChatID === chat_id ? null : chat_id);
      setMenuPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
    }
  };

  const togglePopup2 = () => {
    if (isChatbotFrozen) return;
    setPopupOpen2(!isPopupOpen2);
  };

  // const sortedChats = [...chats].sort((a, b) => {
  //   if (a.pinned === b.pinned) {
  //     return a.id - b.id;
  //   }
  //   return b.pinned - a.pinned;
  // });

  function convertLinks(text) {
    text = text.replace(/\[link \d+\]/gi, '');
    text = text.replace(/\[(https?:\/\/[^\s)]+)\]/g, "")
    text = text.replace(/\((https?:\/\/[^\s)]+)\)/g, '$1')
    const urlRegex = /(https?:\/\/[^\s)]+)/gi;
    // let links = text.match(urlRegex) || []

    return text.replace(urlRegex, (url) => {
      const escapedUrl = url.replace(/[<>"&]/g, (char) => ({
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "&": "&amp;",
      })[char]);
      return `<a href="${escapedUrl}" target="_blank">${url}</a>`;
    });
  }


  const fetch_chats = () => {
    fetch(`${CHATBOT_BASE_URL}/all_chats/${access_token}/`, { method: 'GET', headers: {'Content-Type': 'application/json'}})
    .then((response) => response.json())  
    .then((data) => {
        setChats(data.chats.reverse())
      })
      .catch((error) => {
        console.error("Error:", error);
      }
    );
  }

  const getChat = (chat_id) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
    setCurrentChatID(chat_id)
    fetch(`${CHATBOT_BASE_URL}/show_chat/${chat_id}/`, { method: 'GET', headers: {'Content-Type': 'application/json'}})
    .then((response) => response.json())  
    .then((data) => {
      if (data.error) {
        setChatMessages([])
      } else {
        setChatMessages(filterChats(data.chat_history));
      }
      })
      .catch((error) => {
        console.error("Error:", error);
        setChatMessages([]);
      }
    );
  }

  // This will filter out messages by picking only the last message from consective assistnt messages
  function filterChats(chatHistory) {
    const filteredHistory = [];
    for (let i = 0; i < chatHistory.length; i++) {
        if (
            chatHistory[i].role === "assistant" &&
            i + 1 < chatHistory.length &&
            chatHistory[i + 1].role === "assistant"
        ) {
            continue;
        }
        filteredHistory.push(chatHistory[i]);
    }
    return filteredHistory;
  }

  const handleNewChat = () => {
   setCurrentChatID('');
   setChatMessages([]);
  }

  const handleDeleteChat = (chat) => {
    fetch(`${CHATBOT_BASE_URL}/delete_chat/${chat.chat_id}/`, { method: 'DELETE', headers: {'Content-Type': 'application/json'}})
    .then((response) => response.json())  
    .then((data) => {
        toast.info(`${chat.chat_name} deleted successfully!`)
        setCurrentChatID('');
        setChatMessages([]);
        fetch_chats();
      })
      .catch((error) => {
        console.error("Error:", error);
      }
    );
  }

  useEffect(() => {
    fetch(`${CHATBOT_BASE_URL}/check_access/`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({access_token: access_token})})
      .then((response) => response.json())
      .then((data) => {
        if (data.access) {
          fetch_chats();

          fetch(`${CHATBOT_BASE_URL}/ad_accounts/`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({access_token: access_token})})
            .then((response) => response.json())
            .then((data) => {
              setAdaccounts(data)
            })
            .catch((error) => {
              console.error("Error in fetching ad accounts:", error);
            }
          );
        } else {
          setIschatbotFrozen(true);
          setChatMessages({
            id: 1, content: "Why AdAmigo isn't working for me?", role: 'user',
            id: 2, content: "AdAmigo is sorry to not work for you. Currently, AdAmigo is in the start of journey and only available to limited beta users. AdAmigo's Team is working days and nights and days again to make me available for everyone. You can always request your early access by going to https://www.adamigo.ai", role: 'assistant'
          })
        }
      })
      .catch((error) => {
        console.error("Error in checking access:", error);
        setIschatbotFrozen(true);
        setChatMessages([
          {id: 1, content: "Why AdAmigo isn't working for me?", role: 'user'},
          {id: 2, content: "AdAmigo is sorry to not work for you. Currently, AdAmigo is in the start of journey and only available to limited beta users. AdAmigo's Team is working days and nights and days again to make me available for everyone. You can always request your early access by going to https://www.adamigo.ai", role: 'assistant'}
        ])
      }
    );
  // eslint-disable-next-line
  }, [])

  const chat_history = () => {
    const messagesWithoutId = chatMessages.map(({ id, ...rest }) => rest);
    let numberOfMessages = Math.min(messagesWithoutId.length, 4);
    if (numberOfMessages % 2 !== 0) {numberOfMessages--;}
    return messagesWithoutId.slice(-numberOfMessages);
  };

  const handleSendMessage = (msg) => {
    setIsLoading(true);
    if (msg.trim() !== "") {
      const message = {
        id: chatMessages.length + 1,
        role: 'user',
        content: msg,
        has_file: inputParams.hasFile,
        file_url: inputParams.fileUrl,
        file_type: inputParams.fileType
      };

      const controller = new AbortController();
      abortControllerRef.current = controller;
      const signal = controller.signal;

      const chat_id = currentChatID === '' ? null : currentChatID
      fetch(`${CHATBOT_BASE_URL}/chat/`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
        chat_id: chat_id,
        user_query: msg,
        ad_account: selectedAdaccountId,
        chat_history: JSON.stringify(chat_history()),
        access_token: access_token,
        has_file: inputParams.hasFile,
        file_url: inputParams.fileUrl,
        file_type: inputParams.fileType
      }),
        signal: signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.id && currentChatID === '') {
            setCurrentChatID(data.id);
            fetch_chats();
          }
          setChatMessages([
            ...chatMessages,
            message,
            {
              id: chatMessages.length + 2,
              role: 'assistant',
              content: data.chatbot_reply,
            },
          ]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        }
      );
    }
  };

  const askDatabase = (msg) => {
    if (isChatbotFrozen) return;

    if (selectedAdaccountId === '') {
      toast.error('Ad Account must be selected')
      return
    }
    if (msg.trim() !== "") {
      const message = { id: chatMessages.length + 1, role: 'user', content: msg, has_file: inputParams.hasFile, file_url: inputParams.fileUrl, file_type: inputParams.fileType};
      setChatMessages([...chatMessages, message,]);
      dispatch({ type: 'MESSAGE', payload: '' });
      dispatch({ type: 'FILE_URL', payload: {url: '', has_file: false, file_type: ''}});
      setSelectedFile(null)
      setRowsCount(1);
      handleSendMessage(msg);
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      askDatabase(inputParams.newMessage);
    }
  };

  // const handlePinSwitch = (chat_, flag) => {
  //   const updatedChats = [...chats];
  //   const clicked_chat = chats.filter(chat => chat.id === chat_.id)[0];
  //   const indexOfClickedChat = chats.findIndex(chat => chat.id === clicked_chat.id);
  //   clicked_chat.pinned = flag;
  //   updatedChats[indexOfClickedChat] = clicked_chat;
  //   setChats(updatedChats)
  // }

  // const handleSelectAll = () => {
  //   setIsToggled(!isToggled);
  //   if (selectedAdaccountIds.length === adaccounts.length) {
  //     setSelectedAdaccountIds([])
  //   } else {
  //     const allIds = adaccounts.map(adaccount => adaccount.id);
  //     setSelectedAdaccountIds(allIds);
  //   }
  // }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShouldMenuClose(true);
      setShouldMenuCloseAfterDelay();
      setOpenMenuChatID(null);
    }

    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setAvatarDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (id) => {
    if (!selectedAdaccountId == '') {
      handleNewChat();      
    }
    setSelectedAdaccountId(id);

    // commenting below the logic for handelling multi select selection for selected adAccounts, for use in future
    // if (selectedAdaccountIds.includes(id)) {
    //   setSelectedAdaccountIds(prevSelected => prevSelected.filter(item => item !== id));
    // } else {
    //   setSelectedAdaccountIds(prevSelected => [...prevSelected, id]);
    // }
  };

  const handleCopyText = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  const select_adaccount_text = () => {
    const selectedAdaccount = adaccounts.find(account => account.id === selectedAdaccountId)
    return selectedAdaccount ? selectedAdaccount.name : 'Select an Ad Account';
  }

  const handleFileChange = async (event) => {

    const file = event.target.files[0];
    setSelectedFile(file);
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${CHATBOT_BASE_URL}/upload_file/`, {
        method: 'POST',
        body: formData,
      });

      setIsUploading(false);

      if (response.ok) {
        const fileData = await response.json();
        dispatch({ type: 'FILE_URL', payload: {url: encodeURI(fileData.url), has_file: true, file_type: file.type.startsWith('image/') ? 'image' : 'video'}});
      } else {
        toast.error('File upload failed.');
        setSelectedFile(null);
      }
    } catch (error) {
      setIsUploading(false);
      toast.error('File upload failed.');
      setSelectedFile(null);
      console.error('Error uploading file:', error.response);
    }
  };

  const adjustTextareaRows = (textarea) => {
    const lines = textarea.value.split('\n').length;
    if (lines > maxRows) {
      setCanScroll(true);
    }
    setRowsCount(lines > maxRows ? maxRows : lines);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  });

  const getChatById = (chat_id) => {
    return chats.find(chat => chat.chat_id === chat_id);
  };

  const handleLogout = () => {
    localStorage.setItem('token', '')
    navigate('/login')
  }

  const filteredAdaccounts = Object.values(adaccounts).filter((account) =>
    account.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const TrimAccountName = (chatName) => {
    let trimmedChatName = chatName;
    Object.values(adaccounts).forEach((adaccount) => {
      if (trimmedChatName.includes(adaccount.name)) {
        trimmedChatName = trimmedChatName.replace(adaccount.name, '').trim();
        trimmedChatName = trimmedChatName.replace(':', '').trim();
      }
    });
    return trimmedChatName;
  };

  const accountsToDisplay = searchTerm ? filteredAdaccounts : Object.values(adaccounts) || {}

  return ( <div className="chatbot-page-container"><div className="chatbot-page">

    <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between', paddingTop: '16px'}}>
      <div className="d-flex align-items-center">
        <btn className="ms-4 me-2 drawer-button hover-red" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
          <BsWindowSidebar size={22} />
        </btn>
        <img className="ms-3 header-image" alt="" src="/d.png" />
        <div className="toggle-sidebar">
          <VscThreeBars className="chatbot-header-icon hover-red" onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
        </div>
      </div>
      <ToastContainer className='pt-5' position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
      <div ref={profileRef} style={{position: 'relative'}}>
        <Avatar
          className="avatar-image"
          alt=""
          src={localStorage.getItem('profile_pic') || '/fallback_image.jpg'}
          onClick={() => {
            if (isChatbotFrozen) return;
            setAvatarDropdownOpen(!avatarDropdownOpen)
          }}
          style={{ cursor: 'pointer' }}
        />
        {avatarDropdownOpen && (
          <div className="dropdown-menu show avatar-dropdown-menu" style={{borderRadius: '1em'}}>
            <div
              className="dropdown-item d-flex align-items-center"
              onClick={() => { navigate(ROUTE_PRIVACY_POLICY); setAvatarDropdownOpen(false); }}
              style={{ cursor: 'pointer' }}
            >
              <AiOutlineQuestionCircle className="chatbot-header-icon hover-red" style={{ marginRight: '10px' }} />
              Privacy Policy
            </div>
            <div
              className="dropdown-item d-flex align-items-center"
              onClick={() => { navigate(ROUTE_SETTINGS); setAvatarDropdownOpen(false); }}
              style={{ cursor: 'pointer' }}
            >
              <FiSettings className="chatbot-header-icon hover-red" style={{ marginRight: '10px' }} />
              Settings
            </div>
            <div
              className="dropdown-item d-flex align-items-center"
              onClick={handleLogout}
              style={{ cursor: 'pointer' }}
            >
              <PiSignOutFill className="chatbot-header-icon hover-red" style={{ marginRight: '10px' }} />
              Logout
            </div>
        </div>
        )}
      </div>
    </div>
    <div className="chat-container">
      <div ref={divRef} className={`chat-sidebar ${isSidebarVisible ? 'visible' : ''} ${isDrawerOpen ? 'drawer-open' : ''}`}>
        <div className="d-flex justify-content-between align-items-start">
          <div style={{width: '264px'}}>
            <btn title={select_adaccount_text()} className="d-flex" onClick={togglePopup2} style={{cursor: 'pointer', display: "flex", marginBottom: '4vh', backgroundColor: 'white', borderRadius: '2rem', alignItems: 'center', height: '50px', maxWidth: '100%', color: 'black'}}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#F5F5F4'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}>
              <p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} className="m-0">&nbsp;&nbsp;{select_adaccount_text()}&nbsp;</p>
              <BiSolidRightArrow onClick={togglePopup2} size={12} />&nbsp;
            </btn>
          </div>
          <div className="pt-2 toggle-sidebar">
            <VscThreeBars className="hover-red" onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
          </div>
        </div>
        {/* <iframe src="https://www.hahaha.com/" style={{height: '500px'}} /> */}
        {chats && chats.length > 0 && <div style={{display: 'flex', justifySelf: 'flex-start', alignItems: 'center', marginLeft: '5px'}}>
          Chats
        </div>}

        <div style={{display: 'flex', flexDirection: 'column', maxHeight: '100%', overflowY: 'auto', width: '264px'}}>
          {/* <div style={{display: 'flex', justifySelf: 'flex-start', alignItems: 'center', marginLeft: '5px'}}>
            Pinned
          </div>
          <ul>
            {pinnedChats().map((chat) => (
              <li style={{backgroundColor: `${chat.id === 1 ? '#ed3e63' : 'white'}`, borderRadius: '1rem', color: `${chat.id === 1 ? 'white' : 'black'}`, fontSize: '13px'}} key={chat.id}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = `${chat.id === 1 ? '#ed3e63' : '#F5F5F4'}`} onMouseOut={(e) => e.currentTarget.style.backgroundColor = `${chat.id === 1 ? '#ed3e63' : 'white'}`}>
                <VscPinned style={{ float: 'left', borderRadius: '50%', color: 'black'}} size={18} onClick={() => handlePinSwitch(chat, false)} />
                {chat.name}
              </li>
            ))}
          </ul> */}
          <div>
            {/* {currentChatID === null && <li style={{backgroundColor: '#ed3e63', color: 'white', borderRadius: '1rem', textAlign: 'left'}}>
              new chat
            </li>} */}
            {chats.map(chat => (
              <div className="d-flex mb-1 justify-content-start align-items-center" onClick={() => getChat(chat.chat_id)} style={{cursor: 'pointer', fontSize: 'small',backgroundColor: `${chat.chat_id === currentChatID ? '#ed3e63' : 'white'}`, borderRadius: '1rem', color: `${chat.chat_id === currentChatID ? 'white' : 'black'}`}} key={chat.chat_id} onMouseOver={(e) => e.currentTarget.style.backgroundColor = `${chat.chat_id === currentChatID ? '#ed3e63' : '#F5F5F4'}`} onMouseOut={(e) => e.currentTarget.style.backgroundColor = `${chat.chat_id === currentChatID ? '#ed3e63' : 'white'}`}>

                <div style={{width: `${chat.chat_id === currentChatID ? '88%' : '100%'}`}}>
                  <p style={{overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'left'}} className="my-0 me-2 p-2">{TrimAccountName(chat.chat_name)}</p>
                </div>
                <div className="mx-1" onClick={(e) => {handleThreeDotsClick(chat.chat_id, e)}}>
                  {chat.chat_id === currentChatID && 
                    <BsThreeDots size={20} style={{backgroundColor: 'white', borderRadius: '50%', color: 'black'}}  />
                  }
                </div>
              </div>
            ))}
            {openMenuChatID === currentChatID && (
              <div className="vertical-bar" style={{ top: menuPosition.top + 20, left: menuPosition.left + 20 }} ref={menuRef}>
                <div className="d-flex justify-content-between align-items-center vertical-bar-option text-black" onClick={() => handleDeleteChat(getChatById(currentChatID))}>
                  <ImBin color='#ed3e63' size={22} />
                  <p className="m-0 ms-2">Delete</p>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center vertical-bar-option text-black">
                  <FaMapPin color='#ed3e63' size={22} />
                  <p className="m-0 ms-2">Pin Chat</p>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>

      {openFileUrl && (
        <div className="full-view" onClick={() => setOpenFileUrl(null)}>
          {selectedFile && selectedFile.type.startsWith('image/') ? (
            <img src={openFileUrl} alt="Full-size" onClick={(e) => e.stopPropagation()} />
          ) : (
            <video src={openFileUrl} controls autoPlay onClick={(e) => e.stopPropagation()} />
          )}
          <ImCancelCircle size={40} onClick={() => setOpenFileUrl(null)} color='#ed3e63' style={{ 
            position: 'absolute', 
            top: 25,
            right: 20,
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            boxShadow: '0px 0px 5px rgba(0,0,0,0.3)'
          }} />
        </div>
      )}

      <div className="main-chat-container">
        <div ref={divRef} className="chat-main">
          <ul className="chat-messages p-0">
            {(Array.isArray(chatMessages) ? chatMessages : []).map((message) => (
              <div key={message.id} className="message-container d-flex align-items-center" style={{whiteSpace: 'pre-line'}}>
                {message.role === 'user' ? 
                  <div key={message.id} className="d-flex flex-column">
                    <div className="d-flex mb-2 justify-content-start">
                      <Avatar className="chat-logo" alt="user image" src={localStorage.getItem('profile_pic') || '/fallback_image.jpg'} />
                      <li key={message.id} className='user-message m-0'
                        dangerouslySetInnerHTML={{ __html: convertLinks(message.content)}}>
                      </li>
                      <div>
                        <IconButton className="copy-button hover-red" onClick={() => handleCopyText(message.content)} aria-label="copy message">
                          <MdContentCopy size={18} />
                        </IconButton>
                      </div>
                    </div>
                    {message.has_file && (
                      <>
                        {message.file_type === 'image' &&
                          <img
                            className="chat-file"
                            src={message.file_url}
                            alt="attached_image"
                            onClick={() => setOpenFileUrl(message.file_url)}
                          />}
                        {message.file_type === 'video' &&
                          <video
                            className="chat-file"
                            src={message.file_url}
                            controls
                          />
                        }
                      </>
                    )}
                  </div> : 
                  <div className="d-flex mt-2">
                    <Avatar className="chat-logo" alt="amigo logo" src='/k.png' />
                    <div className='bot-message'>
                      {/* <li key={message.id} style={{fontSize: 'medium'}} dangerouslySetInnerHTML={{ __html: convertLinks(message.content)}} /> */}
                      <Markdown options={{ overrides, forceBlock: true }}>{message.content}</Markdown>
                      <div>
                        <div style={{float: 'right'}}>
                          <button className="btn"><FiThumbsUp className="hover-red" size={18} /></button>
                          <button className="btn mx-1"><FiThumbsDown className="hover-red" size={18} /></button>
                          <button className="btn" onClick={() => handleCopyText(message.content)}><MdContentCopy className="hover-red" size={18} /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            ))}
            {isLoading && <div className="loader-container">
              <div className="chat-bubble">
                <div className="typing">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>}
          </ul>
          {chatMessages.length === 0 && <div className="chat-main2" style={{display: 'grid', paddingBottom: '10px'}}>
            <div style={{alignSelf: 'flex-end'}}>
              <img className="mb-2" alt="logo" width={'70px'} src="/k.png" />
              <h3>How can I help you today?</h3>
            </div>
            <div className="placeholder-messages-container" style={{justifySelf: 'center', alignSelf: 'flex-end'}}>
            <div style={{width: '100%'}} className='d-flex flex-container'>
              <button className="btn btn-secondary mx-2 mb-2" onClick={() => askDatabase('Launch a new ad set.')} style={{borderRadius: '2rem', backgroundColor: '#F5F5F4', color: 'black', width: '100%'}}>Launch a new ad set.</button>
              <button className="btn btn-secondary mb-2" onClick={() => askDatabase('How is performance today?')} style={{borderRadius: '2rem', backgroundColor: '#F5F5F4', color: 'black', width: '100%'}}>How is performance today?</button>
            </div>
            <div style={{width: '100%'}} className="d-flex flex-container">
              <button className="btn btn-secondary mx-2 mb-2" onClick={() => askDatabase('Show active ad sets with 0 purchases.')} style={{borderRadius: '2rem', backgroundColor: '#F5F5F4', color: 'black', width: '100%'}}>Show active ad sets with 0 purchases.</button>
              <button className="btn btn-secondary mb-2" onClick={() => askDatabase('How can I improve my CTR?')} style={{borderRadius: '2rem', backgroundColor: '#F5F5F4', color: 'black', width: '100%'}}>How can I improve my CTR?</button>
            </div></div>
          </div>}

        </div>
        <div className="message-input">
          <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
            {selectedFile ? 
              <div style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
                {selectedFile.type.startsWith('image/') ? (
                <img
                  className="selected-image"
                  src={URL.createObjectURL(selectedFile)}
                  alt="Selected"
                  onClick={() => setOpenFileUrl(isUploading ? URL.createObjectURL(selectedFile) : inputParams.fileUrl)}
                  style={{height: '50px', width: '50px', display: 'block', borderRadius: '0.5em'}}
                />
                ) : (
                <video
                  onClick={() => setOpenFileUrl(isUploading ? URL.createObjectURL(selectedFile) : inputParams.fileUrl)}
                  src={isUploading ? URL.createObjectURL(selectedFile) : inputParams.fileUrl}
                  style={{height: '50px', width: '50px', display: 'block', borderRadius: '0.5em'}}
                />)}
                {isUploading && (
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%'
                  }}>
                    <div className="image-upload-loader"></div>
                  </div>
                )}
                <ImCancelCircle onClick={() => setSelectedFile(null)} color='#ed3e63' style={{ 
                    position: 'absolute', 
                    top: 4,
                    right: 0,
                    background: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '24px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 5px rgba(0,0,0,0.3)'
                  }} />
              </div>
            : <>
            <label htmlFor="file-input" className="file-label">
              <RiImageAddFill size={25} />
            </label>
            <input
              type="file"
              disabled={isChatbotFrozen}
              id="file-input"
              name="file-input"
              className="file-input"
              accept="image/*,video/*"
              onChange={handleFileChange}
            /></>}
          </div>
          <textarea
            type="text"
            placeholder="Type Something"
            className={`py-3 ${canScroll ? 'message-textbox scroll' : 'message-textbox'}`}
            value={inputParams.newMessage}
            onChange={(e) => {
              dispatch({ type: 'MESSAGE', payload: e.target.value });
              adjustTextareaRows(e.target);
            }}
            onKeyDown={handleKeyDown}
            rows={rowsCount}
            style={{ resize: 'none',  }}
          />
          <div className="file-label" style={{borderWidth: 1, height: '100%', display: 'flex', alignItems: 'center'}} >
            <RiSendPlane2Fill size={25} color={`${inputParams.newMessage.trim() === '' ? '' : '#ed3e63'}`} onClick={() => askDatabase(inputParams.newMessage)} />
          </div>
        </div>
      </div>
    </div>

    <Modal isOpen={isPopupOpen2} toggle={() => setPopupOpen2(!isPopupOpen2)}>
      <ModalHeader
        className="text-black"
        toggle={() => {
          setSelectedAdaccountId('');
          setPopupOpen2(!isPopupOpen2)
        }}
      >
        <input
          type="text"
          placeholder="Type to search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </ModalHeader>
      <ModalBody className="text-center">
        {/* <div className="d-flex align-items-center justify-content-center" >
          Select all:&nbsp;&nbsp;
          <div className="toggle-switch" onClick={handleSelectAll}>
            <div className={`slider ${isToggled ? 'toggled' : ''}`}></div>
          </div>
        </div> */}
        <Row className="d-flex justify-content-center mb-4 m-0 ads-model">
          {accountsToDisplay.map((value) => {
            return (
              <Button
                title={value.name}
                key={value.id}
                className="mt-2 text-break text-black border-0 d-block ads-model-button"
                style={{backgroundColor: `${variantValueSalesRegion(value.id)}`, display: 'flex', alignItems: 'center'}}
                onClick={(e) => handleCheckboxChange(e.currentTarget.id)}
                id={value.id}
              >
                {addSign(variantValueSalesRegion(value.id))}
                <Box
                  style={{color: `${variantValueSalesRegion2(value.id)}`}}
                  sx={{
                    borderLeft: "1px solid gray",
                    marginLeft: "8%",
                  }}
                  onClick={(e) => handleCheckboxChange(e.currentTarget.id)}
                  id={value.id}
                >
                  {value.name}
                </Box>
              </Button>
            );
          })}
        </Row>
        <Button
          onClick={() => setPopupOpen2(!isPopupOpen2)}
          className="custom-button border-0"
          style={{backgroundColor: `${selectedAdaccountId != '' && '#ed3e63'}`}}
        >
          Select
        </Button>
      </ModalBody>
    </Modal>
    </div>
  </div>);
};

export default Chatbot;
